import {
    HttpClient,
    HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay } from 'rxjs';

import { Memoize } from '~/app/core/decorators/memoize.decorators';
import { ModuleCodeType } from '~/app/shared/enums/market-event-module-code.enum';
import {
    MarketEventMetadata,
} from '~/app/shared/types/market-event-metadata.type';
import { MarketEvent } from '~/app/shared/types/market-event.type';
import { MarketEventSearchRequest } from '~/app/shared/types/market-events-search-request.type';

@Injectable({
    providedIn: 'root',
})
export class MarketEventsService {
    constructor(private http: HttpClient) {}

    @Memoize({
        isObservable: true,
    })
    getMarketEvents(module: 'FUND' | 'PORTFOLIO' | 'PROJECTION' | 'SCENARIO' = 'FUND') {
        return this.http.get<Readonly<MarketEvent[]>>('/market-events', {
            params: {
                module,
            },
        });
    }

    @Memoize({
        isObservable: true,
    })
    getMarketEventsSearchMetadata(moduleCode?: ModuleCodeType) {
        let params = new HttpParams();
        if (moduleCode) {
            params = params.set('module', moduleCode);
        }

        return this.http.get<Readonly<MarketEventMetadata>>('/market-events/search/metadata', {
            params,
        });
    }

    searchMarketEvents(request: MarketEventSearchRequest) {
        return this.http.post<Readonly<MarketEvent[]>>('/market-events/search', request).pipe(
            delay(300),
        );
    }
}
