import { HttpClient } from '@angular/common/http';
import {
    inject,
    Injectable,
} from '@angular/core';
import {
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
} from 'rxjs/operators';

import { Memoize } from '~/app/core/decorators/memoize.decorators';
import { SrriRequest } from '~/app/shared/types/srri-request.type';
import { SrriVolatility } from '~/app/shared/types/srri-volatility.type';

@Injectable({
    providedIn: 'root',
})
export class SrrisService {
    private http = inject(HttpClient);

    getSrris(allocation: SrriRequest, isSrriExpectedAsFallback: boolean, srriExpected?: number): Observable<Readonly<number>> {
        return this.http.post<Readonly<number>>('/srri/process', allocation).pipe(
            catchError((error) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (error.status === 408 && isSrriExpectedAsFallback && srriExpected) {
                    return of(srriExpected);
                }
                throw error;
            }),
        );
    }

    getSrriVolatilities(): Observable<Readonly<SrriVolatility[]>> {
        return this.getSrriVolatilitiesRaw();
    }

    @Memoize({
        isObservable: true,
    })
    private getSrriVolatilitiesRaw() {
        return this.http.get<Readonly<SrriVolatility[]>>('/srri');
    }
}
