import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Memoize } from '~/app/core/decorators/memoize.decorators';
import { VideoCategory } from '~/app/shared/types/video-category.type';

@Injectable({
    providedIn: 'root',
})
export class VideosService {
    constructor(private http: HttpClient) {}

    @Memoize({
        isObservable: true,
    })
    getVideoCategories(module: 'FUND' | 'PORTFOLIO' = 'FUND') {
        return this.http.get<Readonly<VideoCategory[]>>('/videos/categories', {
            params: {
                module,
            },
        });
    }
}
