export enum CardType {
    FACET = 'FACET',
    RISK_INDICATOR = 'RISK_INDICATOR',
    ACTIVE_RISK = 'ACTIVE_RISK',
    NOTATION = 'NOTATION',
    EXPO = 'EXPO',
    PROJECTION_PERF = 'PROJECTION_PERF',
    SIMULATION_SCENARIO_INDICATORS = 'SIMULATION_SCENARIO_INDICATORS',
    SIMULATION_SCENARIO_SRRI = 'SIMULATION_SCENARIO_SRRI',
}
