export enum FieldValueEnum {
    BOOLEAN = 'boolean',
    TEXT = 'text',
    ARRAY = 'array',
    RISK = 'risk',
    NUMBER = 'number',
    PERCENTAGE = 'percentage',
    OBJECT = 'object',
    EXPO = 'expo',
    DATE = 'date',
}
