import {
    DatePipe,
    DecimalPipe,
    PercentPipe,
} from '@angular/common';
import {
    Inject,
    Injectable,
    LOCALE_ID,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { NOTATION_MARKET_CYCLE_NOT_VISIBLE } from '~/app/core/constants/notations.constants';
import {
    PORTFOLIO_ALERT_RISK_PROFILE,
    PORTFOLIOS_ALERT_FIELDS,
} from '~/app/core/constants/portfolios.constants';
import {
    INDICATOR_PERF,
    INDICATOR_RISK,
} from '~/app/core/constants/risk_indicator_type.constants';
import { ActiveRiskCode } from '~/app/shared/enums/active-risk-code.enum';
import { CardType } from '~/app/shared/enums/card-type.enum';
import { EntityType } from '~/app/shared/enums/entity-type.enum';
import { IndicatorType } from '~/app/shared/enums/indicator-types.enum';
import { MarketCycleCode } from '~/app/shared/enums/market-cycle-code.enum';
import { RiskIndicatorCode } from '~/app/shared/enums/risk-indicator-code.enum';
import { RiskIndicatorType } from '~/app/shared/enums/risk-indicator-type.enum';
import {
    ActiveRisk,
    ActiveRiskValueRequest,
    Notation,
    NotationValueRequest,
    RiskIndicator,
    RiskIndicatorValueRequest,
} from '~/app/shared/interfaces/RiskIndicator';
import { AlertTypeRefs } from '~/app/shared/types/alert-type-refs.type';
import { FieldOption } from '~/app/shared/types/field-option.type';
import { FieldValueEnum } from '~/app/shared/types/field-value-type.type';
import {
    IndicatorForList,
    Sign,
} from '~/app/shared/types/indicator-for-list.type';
import { PortfolioAlert } from '~/app/shared/types/portfolio/portfolio-alert.type';
import { Share } from '~/app/shared/types/shares/share.type';
import { SimulationIndicator } from '~/app/shared/types/simulation-indicator.type';
import { DisplayValue } from '~/app/shared/types/sort-display/display-value.type';
import { TertiaryColors } from '~/app/shared/variables';

@Injectable({
    providedIn: 'root',
})
export class IndicatorsUtilsService {
    private emptyIndicatorStub = {
        type: IndicatorType.EMPTY,
    };

    constructor(
        private translocoService: TranslocoService,
        @Inject(LOCALE_ID) private locale: string,
    ) {
    }

    createIndicatorsForList<T = Share>(
        itemValue: T & {
            simulationIndicators?: Array<SimulationIndicator>,
            riskIndicators?: Array<RiskIndicator>,
            activeRisks?: Array<ActiveRisk>,
            notations?: Array<Notation>,
            alerts?: Array<PortfolioAlert>,
        },
        displayIndicatorValues: DisplayValue[],
        alertsSelected: AlertTypeRefs[],
        mapOptions: {[key:string]: FieldOption},
    ) : Array<IndicatorForList> {
        let indicators: Array<IndicatorForList> = [];

        const riskIndicatorsAlertsSelected = this.getListOfRiskIndicatorSelected(alertsSelected);

        indicators = this.manageAlertsIndicators<T>(indicators, alertsSelected, itemValue, displayIndicatorValues);

        displayIndicatorValues.forEach((displayIndicatorValue) => {
            let currentIndicator: IndicatorForList | null = null;
            switch (displayIndicatorValue.type) {
                case CardType.FACET: {
                    currentIndicator = this.manageFacetIndicators<T>(mapOptions, displayIndicatorValue, itemValue);
                    break;
                }
                case CardType.EXPO: {
                    currentIndicator = this.manageExpoIndicators<T>(displayIndicatorValue, itemValue);
                    break;
                }
                case CardType.NOTATION: {
                    currentIndicator = this.manageNotationIndicators<T>(displayIndicatorValue, itemValue);
                    break;
                }
                case CardType.RISK_INDICATOR: {
                    currentIndicator = this.manageRiskIndicators<T>(riskIndicatorsAlertsSelected, displayIndicatorValue, itemValue);
                    break;
                }
                case CardType.ACTIVE_RISK: {
                    currentIndicator = this.manageActiveRiskIndicators<T>(displayIndicatorValue, itemValue);
                    break;
                }
                case CardType.PROJECTION_PERF: {
                    currentIndicator = this.manageProjectionIndicators<T>(displayIndicatorValue, itemValue);
                    break;
                }
                case CardType.SIMULATION_SCENARIO_INDICATORS: {
                    currentIndicator = this.manageSimulationScenarioIndicators<T>(displayIndicatorValue, itemValue);
                    break;
                }
                case CardType.SIMULATION_SCENARIO_SRRI: {
                    currentIndicator = this.manageSimulationScenarioSrri<T>(displayIndicatorValue, itemValue);
                    break;
                }
                default:
                    break;
            }
            if (currentIndicator) {
                indicators.push(currentIndicator);
            }
        });

        return indicators;
    }

    getListOfRiskIndicatorSelected(alertsSelected: AlertTypeRefs[]) {
        return alertsSelected.reduce((acc: { code: string, period: string }[], alertSelected) => {
            if (alertSelected.id !== PORTFOLIO_ALERT_RISK_PROFILE) {
                acc.push({
                    code: alertSelected.riskIndicatorCode,
                    period: alertSelected.riskIndicatorPeriod,
                });
            }
            return acc;
        }, []);
    }

    manageAlertsIndicators<T = Share>(
        indicators: Array<IndicatorForList>,
        alertsSelected: AlertTypeRefs[],
        itemValue: T & {
            riskIndicators?: Array<RiskIndicator>,
            alerts?: Array<PortfolioAlert>,
        },
        displayIndicatorValues: DisplayValue[],
    ) {
        // Add alert if there is a alert, otherwise we display classic indicators (risk profile or risk indicator)
        alertsSelected.forEach((alertSelected) => {
            const alert = itemValue.alerts?.find((_alert) => _alert.type === alertSelected.id);

            if (alert) {
                return indicators.push({
                    type: IndicatorType.ALERT,
                    alert,
                    isAlert: true,
                    displayed: false,
                });
            }
            // No alert
            if (alertSelected.id === PORTFOLIO_ALERT_RISK_PROFILE) {
                const indicatorVal = itemValue['calculatedSrri' as keyof T];

                if (typeof indicatorVal === 'number' && indicatorVal) {
                    return indicators.push({
                        id: 'calculatedSrri',
                        type: IndicatorType.RISK_PROFILE,
                        isAlert: true,
                        label: this.translocoService.translate('common.risk_profile_calculated'),
                        value: indicatorVal,
                        displayed: false,
                    });
                }

                return indicators.push({
                    ...this.emptyIndicatorStub,
                    isAlert: true,
                });
            }

            // No display alert / indicator if user disabled the display
            if (!displayIndicatorValues.find((displayIndicator) => alertSelected.riskIndicatorCode === displayIndicator.code
                && alertSelected.riskIndicatorPeriod === displayIndicator.period)) {
                return null;
            }
            const riskIndicator = itemValue.riskIndicators?.find(
                (_riskIndicator) => alertSelected.riskIndicatorCode === _riskIndicator.code && alertSelected.riskIndicatorPeriod === _riskIndicator.period,
            );

            if (!riskIndicator) {
                return indicators.push({
                    ...this.emptyIndicatorStub,
                    isAlert: true,
                });
            }

            return indicators.push({
                id: `${riskIndicator.code}-${riskIndicator.period}`,
                type: IndicatorType.RISK_INDICATOR,
                isAlert: true,
                label: riskIndicator.name,
                period: riskIndicator.period,
                value: riskIndicator.value,
                values: riskIndicator.values,
                displayed: false,
            });
        });
        return indicators;
    }

    manageFacetIndicators<T = Share>(
        mapOptions: {[key:string]: FieldOption},
        indicatorValue: DisplayValue,
        itemValue: T,
    ): IndicatorForList | null {
        let facetValue: string | number;
        let facetType = IndicatorType.TEXT;
        const datePipe = new DatePipe(this.locale);
        const percentPipe = new PercentPipe(this.locale);

        const indicatorVal = itemValue[indicatorValue.code as keyof T];
        if (
            indicatorVal !== null
            && indicatorVal !== undefined
            && mapOptions[indicatorValue.code]
        ) {
            switch (mapOptions[indicatorValue.code].type) {
                case FieldValueEnum.BOOLEAN:
                    facetValue = indicatorVal ? this.translocoService.translate('common.yes') : this.translocoService.translate('common.no');
                    facetType = IndicatorType.TEXT;
                    break;
                case FieldValueEnum.DATE:
                    if (typeof indicatorVal !== 'string') return null;
                    facetValue = datePipe.transform(indicatorVal) || '';
                    facetType = IndicatorType.TEXT;
                    break;
                case FieldValueEnum.ARRAY:
                    if (!Array.isArray(indicatorVal) || !(indicatorVal).length) return null;
                    facetValue = indicatorVal.join(', ');
                    facetType = IndicatorType.TEXT;
                    break;
                case FieldValueEnum.RISK:
                    if (typeof indicatorVal !== 'number') return null;
                    facetValue = indicatorVal;
                    facetType = IndicatorType.RISK_PROFILE;
                    break;
                case FieldValueEnum.NUMBER:
                    if (typeof indicatorVal !== 'number') return null;
                    facetValue = this.beautifyNumber(indicatorVal);
                    facetType = IndicatorType.NUMBER;
                    break;
                case FieldValueEnum.PERCENTAGE:
                case FieldValueEnum.EXPO:
                    if (typeof indicatorVal !== 'number') return null;
                    facetValue = percentPipe.transform(indicatorVal, '1.0-2') || '';
                    facetType = IndicatorType.PERCENTAGE;
                    break;
                case FieldValueEnum.OBJECT:
                    if (typeof indicatorVal !== 'object') return null;
                    facetValue = (indicatorVal as unknown as { name: string })?.name;
                    facetType = IndicatorType.TEXT;
                    break;
                case FieldValueEnum.TEXT:
                default:
                    if (typeof indicatorVal !== 'number' && typeof indicatorVal !== 'string') return null;
                    facetValue = indicatorVal;
                    facetType = IndicatorType.TEXT;
                    break;
            }

            return {
                id: indicatorValue.code,
                type: facetType,
                label: this.translocoService.translate<string>(mapOptions[indicatorValue.code].name),
                value: facetValue,
                displayed: false,
                sign: this.getSign(facetValue),
            };
        }
        if (mapOptions[indicatorValue.code]) {
            return this.emptyIndicatorStub;
        }
        return null;
    }


    manageExpoIndicators<T = Share>(
        indicatorValue: DisplayValue,
        itemValue: T,
    ): IndicatorForList {
        const fieldObject = itemValue[indicatorValue.field as keyof T];
        const percentPipe = new PercentPipe(this.locale);

        // @ts-ignore
        if (!fieldObject || typeof fieldObject[indicatorValue.code] !== 'object') {
            return this.emptyIndicatorStub;
        }
        // @ts-ignore
        const fieldValue = fieldObject[indicatorValue.code] as unknown as { name: string, weight: number };

        const facetValue = percentPipe.transform(fieldValue.weight ?? 0, '1.0-2') || '';
        const label = `${this.translocoService.translate<string>('common.expo')} ${fieldValue.name}`;

        return {
            id: indicatorValue.code,
            type: IndicatorType.PERCENTAGE,
            label,
            value: facetValue,
            displayed: false,
        };
    }

    manageProjectionIndicators<T = Share>(
        indicatorValue: DisplayValue,
        itemValue: T & {
            annualReturn?: {
                worst: number,
                best: number,
                expected: number,
                scenario: number | null,
            },
        },
    ): IndicatorForList {
        let label = '';
        let value = 0;
        switch (indicatorValue.code) {
            case 'CUSTOM_PERF_EXPECTED':
                label = this.translocoService.translate('projection.expected');
                value = itemValue.annualReturn?.expected || 0;
                break;
            case 'CUSTOM_PERF_BEST':
                label = this.translocoService.translate('projection.best');
                value = itemValue.annualReturn?.best || 0;
                break;
            case 'CUSTOM_PERF_WORST':
                label = this.translocoService.translate('projection.worst');
                value = itemValue.annualReturn?.worst || 0;
                break;
            case 'CUSTOM_PERF_SCENARIO':
                label = (indicatorValue.field as string).replace(this.translocoService.translate('projection.expected_return'), '');
                value = itemValue.annualReturn?.scenario || 0;
                break;
            default:
                label = '';
                break;
        }

        return {
            id: indicatorValue.code,
            type: IndicatorType.PROJECTION_PERF,
            label,
            value: (value * 100).toFixed(2),
            displayed: false,
            sign: this.getSign(value),
            explanationKey: 'explanation.expected-return',
        };
    }

    manageSimulationScenarioIndicators<T = Share>(
        indicatorValue: DisplayValue,
        itemValue: T & {
            indicators?: SimulationIndicator[],
        },
    ): IndicatorForList | null {
        const riskIndicator = itemValue.indicators?.find(
            (_riskIndicator) => indicatorValue.code === _riskIndicator.code && (!_riskIndicator.extra || indicatorValue.period === _riskIndicator.extra),
        );

        if (!riskIndicator) {
            return this.emptyIndicatorStub;
        }

        const riskIndicatorTmp: IndicatorForList = {
            id: `${riskIndicator.code}-${riskIndicator.extra ?? ''}`,
            type: IndicatorType.SIMULATION_SCENARIO,
            label: riskIndicator.name,
            subLabel: indicatorValue.field,
            value: riskIndicator.value,
            displayed: false,
        };

        return riskIndicatorTmp;
    }

    manageSimulationScenarioSrri<T = Share>(
        indicatorValue: DisplayValue,
        itemValue: T & {
            indicators?: SimulationIndicator[],
        },
    ): IndicatorForList | null {
        const riskIndicator = itemValue.indicators?.find(
            (_riskIndicator) => indicatorValue.code === _riskIndicator.code && (!_riskIndicator.extra || indicatorValue.period === _riskIndicator.extra),
        );

        if (!riskIndicator) {
            return this.emptyIndicatorStub;
        }

        const riskIndicatorTmp: IndicatorForList = {
            id: indicatorValue.code,
            type: IndicatorType.RISK_PROFILE,
            label: this.translocoService.translate<string>('common.srri'),
            subLabel: riskIndicator.name,
            value: riskIndicator.value,
            displayed: false,
        };

        return riskIndicatorTmp;
    }

    manageNotationIndicators<T = Share>(
        indicatorValue: DisplayValue,
        itemValue: T & {
            notations?: Array<Notation>,
        },
    ): IndicatorForList {
        const notation = itemValue.notations?.find(
            (currentNotation: Notation) => indicatorValue.code === currentNotation.code && indicatorValue.benchmark === currentNotation.benchmark
                && ((currentNotation?.period && indicatorValue?.period && indicatorValue.period === currentNotation.period)
                    || (!currentNotation.period && !indicatorValue.period)),
        );

        if (!notation) {
            return this.emptyIndicatorStub;
        }

        return {
            id: notation.code,
            type: IndicatorType.NOTATION,
            label: notation.shortName,
            value: notation.value,
            benchmark: indicatorValue.benchmark || '',
            marketCycle: notation.marketCycle || '',
            displayed: false,
            period: notation.period || undefined,
            displayMarketCycle: !NOTATION_MARKET_CYCLE_NOT_VISIBLE.includes(notation.code),
            sign: this.getSign(notation.value),
        };
    }

    manageRiskIndicators<T = Share>(
        riskIndicatorsAlertsSelected: { code: string, period: string }[],
        indicatorValue: DisplayValue,
        itemValue: T & {
            riskIndicators?: Array<RiskIndicator>,
        },
    ): IndicatorForList | null {
        // Check if risk indicator already added by alert configuration
        if (riskIndicatorsAlertsSelected.find(
            (riskIndicatorsAlert) => indicatorValue.code === riskIndicatorsAlert.code && indicatorValue.period === riskIndicatorsAlert.period,
        )) return null;

        const riskIndicator = itemValue.riskIndicators?.find(
            (_riskIndicator) => indicatorValue.code === _riskIndicator.code && (!_riskIndicator.period || indicatorValue.period === _riskIndicator.period),
        );

        if (!riskIndicator) {
            return this.emptyIndicatorStub;
        }

        const sign = this.getSign(riskIndicator.value);
        const riskIndicatorType = this.getRiskIndicatorTypeByCode(riskIndicator.code as RiskIndicatorCode | ActiveRiskCode);
        const riskIndicatorTmp: IndicatorForList = {
            id: `${riskIndicator.code}-${riskIndicator.period}`,
            type: IndicatorType.RISK_INDICATOR,
            label: riskIndicator.name,
            period: riskIndicator.period,
            value: riskIndicator.value,
            values: riskIndicator.values,
            displayed: false,
            sign,
            explanationKey: '',
            riskIndicatorType,
            color: this.getIndicatorColor(sign, riskIndicatorType),
        };

        if (riskIndicator.values) {
            riskIndicatorTmp.x = riskIndicator.values.map((value) => value.date);
            riskIndicatorTmp.y = riskIndicator.values.map((value) => value.value);
        }

        return riskIndicatorTmp;
    }


    manageActiveRiskIndicators<T = Share>(
        indicatorValue: DisplayValue,
        itemValue: T & {
            activeRisks?: Array<ActiveRisk>,
        },
    ): IndicatorForList {
        const activeRisk = itemValue.activeRisks?.find(
            (_activeRisk) => indicatorValue.benchmark === _activeRisk.benchmark
            && indicatorValue.code === _activeRisk.code
            && (!_activeRisk.period || indicatorValue.period === _activeRisk.period),
        );

        if (!activeRisk) {
            return this.emptyIndicatorStub;
        }
        const sign = this.getSign(activeRisk.value);
        const riskIndicatorType = this.getRiskIndicatorTypeByCode(activeRisk.code as RiskIndicatorCode | ActiveRiskCode);
        return {
            id: `${activeRisk.code}-${activeRisk.period}`,
            type: IndicatorType.ACTIVE_RISK,
            label: activeRisk.name,
            period: activeRisk.period,
            benchmark: indicatorValue.benchmark || '',
            value: activeRisk.value,
            displayed: false,
            sign,
            riskIndicatorType,
            color: this.getIndicatorColor(sign, riskIndicatorType),
        };
    }

    createIndicatorsForRequests(displayIndicator: DisplayValue[], alertsSelected: AlertTypeRefs[] = []) {
        const defaultRiskIndicators: Array<RiskIndicatorValueRequest> = [];
        const defaultActiveRisks: Array<ActiveRiskValueRequest> = [];
        const defaultNotations: Array<NotationValueRequest> = [];

        displayIndicator.forEach((displayIndicatorValue) => {
            switch (displayIndicatorValue.type) {
                case CardType.NOTATION:
                    defaultNotations.push({
                        code: displayIndicatorValue.code,
                        marketCycle: displayIndicatorValue.marketCycle || '',
                        benchmark: displayIndicatorValue.benchmark || '',
                        ...(displayIndicatorValue.period ? { period: displayIndicatorValue.period } : {}),
                    });

                    break;
                case CardType.RISK_INDICATOR:
                    if (displayIndicatorValue.period) {
                        defaultRiskIndicators.push({
                            code: displayIndicatorValue.code,
                            period: displayIndicatorValue.period,
                            marketCycle: MarketCycleCode.OV,
                        });
                    }
                    break;
                case CardType.ACTIVE_RISK:
                    if (displayIndicatorValue.period) {
                        defaultActiveRisks.push({
                            code: displayIndicatorValue.code,
                            period: displayIndicatorValue.period,
                            marketCycle: displayIndicatorValue.marketCycle || '',
                            benchmark: displayIndicatorValue.benchmark || '',
                        });
                    }
                    break;
                default:
                    break;
            }
        });

        alertsSelected.forEach((alertSelected) => {
            if (alertSelected.id !== PORTFOLIO_ALERT_RISK_PROFILE) {
                defaultRiskIndicators.push({
                    code: alertSelected.riskIndicatorCode,
                    period: alertSelected.riskIndicatorPeriod,
                    marketCycle: 'OV',
                });
            }
        });

        return {
            defaultRiskIndicators,
            defaultActiveRisks,
            defaultNotations,
        };
    }

    createFieldsForRequest(displayIndicator: DisplayValue[], alertIds: number[], defaultFields: string = '') {
        let fields: string = (defaultFields ? `${defaultFields},` : defaultFields);
        if (alertIds.length) fields += `${PORTFOLIOS_ALERT_FIELDS.join(',')},`;

        displayIndicator.forEach((displayIndicatorValue) => {
            switch (displayIndicatorValue.type) {
                case CardType.FACET:
                    if (fields.indexOf(`${displayIndicatorValue.code},`) === -1) {
                        fields += `${displayIndicatorValue.code},`;
                    }
                    break;
                case CardType.EXPO:
                    if (displayIndicatorValue.field && fields.indexOf(`${displayIndicatorValue.field},`) === -1) {
                        fields += `${displayIndicatorValue.field},`;
                    }
                    break;
                default:
                    break;
            }
        });
        return fields;
    }

    createReferencesForRequest(sharesId: Array<number>, categoriesId: Array<number>) {
        return [
            ...sharesId.map((shareId) => ({
                id: shareId,
                type: EntityType.SHARE,
            })),
            ...categoriesId.map((categoryId) => ({
                id: categoryId,
                type: EntityType.CATEGORY,
            })),
        ];
    }

    beautifyNumber(value: number) {
        const decimalPipe = new DecimalPipe(this.locale);
        return decimalPipe.transform(value, '1.0-2') || '';
    }

    getIndicatorColor(sign: Sign, riskIndicatorType: RiskIndicatorType): Lowercase<keyof typeof TertiaryColors> {
        let indicatorColor = 'grey';
        if (riskIndicatorType === RiskIndicatorType.RISK) {
            return indicatorColor as Lowercase<keyof typeof TertiaryColors>;
        }
        switch (sign) {
            case 'positive':
                indicatorColor = 'green01';
                break;
            case 'negative':
                indicatorColor = 'red';
                break;
            default:
                indicatorColor = 'grey';
        }
        return indicatorColor as Lowercase<keyof typeof TertiaryColors>;
    }

    getRiskIndicatorTypeByCode(indicatorCode: RiskIndicatorCode | ActiveRiskCode): RiskIndicatorType {
        if (INDICATOR_PERF.includes(indicatorCode)) {
            return RiskIndicatorType.PERF;
        }
        if (INDICATOR_RISK.includes(indicatorCode)) {
            return RiskIndicatorType.RISK;
        }
        return RiskIndicatorType.PERF_RISK_RATIO;
    }

    getSign(value: string | number): Sign {
        let parsedValue = 0;
        if (typeof value === 'number') {
            parsedValue = value;
        }
        if (typeof value === 'string') {
            parsedValue = parseFloat(value.replace('%', ''));
        }
        return this.getNumericSignValue(parsedValue);
    }

    getNumericSignValue(numericValue: number): Sign {
        switch (Math.sign(numericValue)) {
            case 1:
                return 'positive';
            case -1:
                return 'negative';
            default:
                return 'neutral';
        }
    }
}
