import { pick } from 'ramda';

import { PortfolioType } from '~/app/shared/enums/portfolio-type.enum';
import { AllocationToUpdate } from '~/app/shared/types/allocation/allocation-to-update.type';
import { Allocation } from '~/app/shared/types/allocation/allocation.type';
import { CashAllocationToUpdate } from '~/app/shared/types/cash-allocation-to-update.type';
import { CreatePortfolio } from '~/app/shared/types/portfolio/create-portfolio.type';
import { PortfolioCreatingForm } from '~/app/shared/types/portfolio/portfolio-creating-form.type';
import { PortfolioRequest } from '~/app/shared/types/portfolio/portfolio-request.type';
import { Portfolio } from '~/app/shared/types/portfolio/portfolio.type';
import { ShareAllocationToUpdate } from '~/app/shared/types/share-allocation-to-update.type';

export type AllocationParams = {
    amount?: number,
    shares: ShareAllocationToUpdate[],
    cashes: CashAllocationToUpdate[],
}

export function initPortfolio(
    values: PortfolioCreatingForm,
    portfolioType: PortfolioType,
    allocation: AllocationParams,
    sourcePortfolio: Portfolio | null = null,
    withInitialAllocation: boolean = true,
): CreatePortfolio {
    return {
        amount: 0,
        creationDate: new Date(values.creationDate).toISOString(),
        currency: typeof values.currency === 'object' ? values.currency?.id : values.currency,
        documents: sourcePortfolio?.documents || [],
        name: values.name,
        philosophy: sourcePortfolio?.philosophy || null,
        comment: sourcePortfolio?.comment || null,
        riskProfile: values.riskProfile,
        shares: [],
        srri: typeof values.srri === 'object' ? values.srri.id : values.srri,
        videos: sourcePortfolio?.videos || [],
        type: {
            id: portfolioType,
            name: 'model',
        },
        allocation,
        initialAllocation: withInitialAllocation ? {
            shares: allocation.shares.map((shareAlloc) => pick(['shareId', 'quantity', 'amount', 'weight'], shareAlloc)),
            cashes: allocation.cashes.map((cashAlloc) => pick(['currency', 'quantity', 'amount', 'weight'], cashAlloc)),
        } : null,
        allocationStyleId: sourcePortfolio?.allocationStyle?.id,
        investGeographyId: sourcePortfolio?.investGeography?.id,
        ...(values.investCategory ? { investCategoryId: values.investCategory.id } : {}),
        ...(values.client ? {
            clientId: values.client.id,
            client: {
                id: values.client.id,
                firstName: values.client.data.firstName,
                lastName: values.client.data.lastName,
            },
        } : {}),
        ...(sourcePortfolio ? { duplicateFromPortfolioId: sourcePortfolio.id } : {}),
    };
}

export function formattedAllocation(allocation: Allocation, currency: string, portfolioType: PortfolioType, withCurrentWeight: boolean = true) {
    const portfolioAllocation: AllocationToUpdate = {
        shares: allocation.shares.map((share) => ({
            shareId: share.shareId,
            ...(portfolioType === PortfolioType.REAL ? { amount: share.amount } : {}),
            ...(portfolioType === PortfolioType.MODEL || share.weight ? { weight: share.weight } : {}),
            currentWeight: share.weight,
        })),
        cashes: allocation.cashes.map((cash) => ({
            currency: cash.currency,
            ...(portfolioType === PortfolioType.REAL ? { amount: cash.amount } : {}),
            ...(portfolioType === PortfolioType.MODEL || cash.weight ? { weight: cash.weight } : {}),
            currentWeight: cash.weight,
        })),
    };

    if (!portfolioAllocation.cashes.find((cash) => cash.currency === currency)) {
        if (portfolioType === PortfolioType.REAL) {
            portfolioAllocation.cashes.push({
                currency,
                amount: 0,
                ...(withCurrentWeight ? { currentWeight: 0 } : {}),
            });
        } else {
            let totalWeight = 0;
            portfolioAllocation.shares.forEach((shareAlloc) => {
                totalWeight += shareAlloc.weight ?? 0;
            });
            portfolioAllocation.cashes.forEach((shareAlloc) => {
                totalWeight += shareAlloc.weight ?? 0;
            });
            const liquidityWeight = parseFloat((1 - totalWeight).toPrecision(4));
            portfolioAllocation.cashes.push({
                currency,
                weight: liquidityWeight,
                ...(withCurrentWeight ? { currentWeight: liquidityWeight } : {}),
            });
        }
    }

    return portfolioAllocation;
}

export function createPortfolioForRequest(portfolio: CreatePortfolio) : PortfolioRequest {
    return {
        name: portfolio.name,
        type: portfolio.type.id,
        srri: portfolio.srri,
        riskProfile: portfolio.riskProfile,
        currency: portfolio.currency,
        creationDate: portfolio.creationDate,
        allocation: portfolio.allocation,
        ...(portfolio.initialAllocation ? { initialAllocation: portfolio.initialAllocation } : {}),
        ...(portfolio.clientId ? { clientId: portfolio.clientId } : {}),
        ...(portfolio.contextId ? { contexts: [portfolio.contextId] } : {}),
        ...(portfolio.investGeographyId ? { investGeographyId: portfolio.investGeographyId } : {}),
        ...(portfolio.investCategoryId ? { investCategoryId: portfolio.investCategoryId } : {}),
        ...(portfolio.allocationStyleId ? { allocationStyleId: portfolio.allocationStyleId } : {}),
        ...(portfolio.managementFee ? { managementFee: portfolio.managementFee } : {}),
        ...(portfolio.philosophy ? { philosophy: portfolio.philosophy } : {}),
        ...(portfolio.comment ? { comment: portfolio.comment } : {}),
        ...(portfolio.rebalancingFrequency ? { rebalancingFrequency: portfolio.rebalancingFrequency as string } : {}),
        ...(portfolio.firstRebalancingDate ? { firstRebalancingDate: portfolio.firstRebalancingDate } : {}),
        ...(portfolio.amount ? { amount: portfolio.amount } : {}),
        ...(portfolio.duplicateFromPortfolioId ? { duplicateFromPortfolioId: portfolio.duplicateFromPortfolioId } : {}),
        ...(portfolio.documents?.length ? {
            documentIdsToDuplicate: portfolio.documents?.filter((doc) => doc.id !== undefined).map((doc) => doc.id as string),
        } : {}),
        ...(portfolio.videos?.length ? {
            videoIdsToDuplicate: portfolio.videos?.map((doc) => doc.id),
        } : {}),
    };
}

export function updateAllocationForRequest(allocation: {
    shares: ShareAllocationToUpdate[],
    cashes: CashAllocationToUpdate[],
}, type: PortfolioType) {
    if (type === PortfolioType.MODEL) {
        return {
            shares: allocation.shares.map((shareAlloc) => ({
                shareId: shareAlloc.shareId,
                weight: shareAlloc.newAllocationPrices?.weight,
            })),
            cashes: allocation.cashes.map((cashAlloc) => ({
                currency: cashAlloc.currency,
                weight: cashAlloc.newAllocationPrices?.weight,
            })),
        };
    }
    return {
        shares: allocation.shares.reduce((acc: ShareAllocationToUpdate[], shareAlloc) => {
            if (shareAlloc.diffAllocationPrices?.amount) {
                acc.push({
                    shareId: shareAlloc.shareId,
                    amount: shareAlloc.diffAllocationPrices?.amount,
                });
            }
            return acc;
        }, []),
        cashes: allocation.cashes.reduce((acc: CashAllocationToUpdate[], cashAlloc) => {
            if (cashAlloc.diffAllocationPrices?.amount) {
                acc.push({
                    currency: cashAlloc.currency,
                    amount: cashAlloc.diffAllocationPrices?.amount,
                });
            }
            return acc;
        }, []),
    };
}
