import { CardType } from '~/app/shared/enums/card-type.enum';
import { Period } from '~/app/shared/enums/period.enum';
import { RiskIndicatorCode } from '~/app/shared/enums/risk-indicator-code.enum';
import { FieldOption } from '~/app/shared/types/field-option.type';
import {
    FieldValueEnum,
} from '~/app/shared/types/field-value-type.type';
import { PortfolioSearchSuggestionLabel } from '~/app/shared/types/portfolio/portfolio-search-suggestion.type';
import { DisplayValue } from '~/app/shared/types/sort-display/display-value.type';
import { SortOption } from '~/app/shared/types/sort-display/sort-option.type';

export const PORTFOLIOS_SEARCH_FIELDS = [
    'advicedPortfolioCount',
    'company',
    'isAdvice',
    'principalVideo',
    'type',
    'shareCount',
    'logo',
    'name',
    'client',
    'amount',
    'investedWeight',
    'portfolioAdviceId',
    'currency',
    'permissions',
];

export const PORTFOLIOS_DEFAULT_OPTIONS: {[key:string]: FieldOption} = {
    srri: {
        code: 'srri',
        name: 'portfolio.sort.fields.default.risk_profile',
        type: FieldValueEnum.RISK,
        translatable: true,
        sortable: true,
        isVisible: true,
    },
    currency: {
        code: 'currency',
        name: 'portfolio.sort.fields.default.currency',
        type: FieldValueEnum.TEXT,
        translatable: true,
        sortable: true,
        isVisible: false,
    },
};

export const PORTFOLIOS_OTHER_OPTIONS: {[key:string]: FieldOption} = {
    liquidityWeight: {
        code: 'liquidityWeight',
        name: 'portfolio.sort.fields.other.liquidity',
        type: FieldValueEnum.EXPO,
        translatable: true,
        sortable: true,
        isVisible: true,
    },
    sfdrArticle8Weight: {
        code: 'sfdrArticle8Weight',
        name: 'portfolio.sort.fields.other.sfdr_article_8',
        type: FieldValueEnum.EXPO,
        translatable: true,
        sortable: true,
        isVisible: false,
    },
    sfdrArticle9Weight: {
        code: 'sfdrArticle9Weight',
        name: 'portfolio.sort.fields.other.sfdr_article_9',
        type: FieldValueEnum.EXPO,
        translatable: true,
        sortable: true,
        isVisible: false,
    },
    sriWeight: {
        code: 'sriWeight',
        name: 'portfolio.sort.fields.other.sri',
        type: FieldValueEnum.EXPO,
        translatable: true,
        sortable: true,
        isVisible: false,
    },
    greenfinWeight: {
        code: 'greenfinWeight',
        name: 'portfolio.sort.fields.other.greenfin',
        type: FieldValueEnum.EXPO,
        translatable: true,
        sortable: true,
        isVisible: false,
    },
    advisors: {
        code: 'advisors',
        name: 'portfolio.sort.fields.other.advisor_name',
        type: FieldValueEnum.ARRAY,
        translatable: true,
        sortable: true,
        isVisible: true,
    },
    createdDate: {
        code: 'createdDate',
        name: 'portfolio.sort.fields.other.created_at',
        type: FieldValueEnum.DATE,
        translatable: true,
        sortable: true,
        isVisible: true,
    },
    managementFee: {
        code: 'managementFee',
        name: 'portfolio.sort.fields.other.advisor_fees',
        type: FieldValueEnum.PERCENTAGE,
        translatable: true,
        sortable: true,
        isVisible: false,
    },
    rebalancingFrequency: {
        code: 'rebalancingFrequency',
        sortCode: 'rebalancingFrequency',
        name: 'portfolio.sort.fields.other.rebalancing',
        type: FieldValueEnum.OBJECT,
        translatable: true,
        sortable: true,
        isVisible: false,
    },
    allocationStyle: {
        code: 'allocationStyle',
        name: 'portfolio.sort.fields.other.allocation_style',
        type: FieldValueEnum.OBJECT,
        translatable: true,
        sortable: false,
        isVisible: false,
    },
};

export const CARD_DEFAULT_UID = 'DEFAULT';
export const CARD_ASSET_UNIVERSE_WEIGHTS_UID = 'ASSET_UNIVERSE_WEIGHTS';
export const CARD_CATEGORY_WEIGHTS_UID = 'CATEGORY_WEIGHTS';
export const CARD_GEOGRAPHY_WEIGHTS_UID = 'GEOGRAPHY_WEIGHTS';
export const CARD_ASSURANCE_VIE_WEIGHTS_UID = 'ASSURANCE_VIE_WEIGHTS';
export const CARD_RISK_INDICATORS_UID = 'RISK_INDICATORS';
export const CARD_OTHERS_UID = 'OTHER';

export const PORTFOLIOS_DEFAULT_SORT_OPTIONS: SortOption[] = [
    {
        name: 'portfolio.sort.fields.default.name',
        type: CardType.FACET,
        sortCode: 'name',
        value: {
            uid: CARD_DEFAULT_UID,
            type: CardType.FACET,
            code: 'name',
        },
    },
    {
        name: 'portfolio.sort.fields.default.client',
        type: CardType.FACET,
        sortCode: 'client',
        value: {
            uid: CARD_DEFAULT_UID,
            type: CardType.FACET,
            code: 'client',
        },
    },
    {
        name: 'portfolio.sort.fields.default.advisor_amount',
        type: CardType.FACET,
        sortCode: 'amount',
        value: {
            uid: CARD_DEFAULT_UID,
            type: CardType.FACET,
            code: 'amount',
        },
    },
    {
        name: 'portfolio.sort.fields.default.invested_weight',
        type: CardType.FACET,
        sortCode: 'investedWeight',
        value: {
            uid: CARD_DEFAULT_UID,
            type: CardType.FACET,
            code: 'investedWeight',
        },
    },
];

export const PORTFOLIOS_DEFAULT_DISPLAY_VALUE: DisplayValue[] = [
    {
        uid: CARD_DEFAULT_UID,
        type: CardType.FACET,
        code: 'srri',
    },
    {
        uid: CARD_DEFAULT_UID,
        type: CardType.FACET,
        code: 'currency',
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.ANRT,
        period: Period.PSIC,
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.VOLT,
        period: Period.PSIC,
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.SHRT,
        period: Period.PSIC,
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.MADD,
        period: Period.PSIC,
    },
    {
        uid: CARD_OTHERS_UID,
        type: CardType.FACET,
        code: 'liquidityWeight',
    },
    {
        uid: CARD_OTHERS_UID,
        type: CardType.FACET,
        code: 'sriWeight',
    },
];

export const PORTFOLIOS_DEFAULT_DISPLAY_VALUE_SHORTLIST_RISK_INDICATORS = [
    RiskIndicatorCode.PERF,
    RiskIndicatorCode.MADD,
    RiskIndicatorCode.SHRT,
    RiskIndicatorCode.VOLT,
];

export const PORTFOLIOS_ALERT_FIELDS = [
    'alerts',
    'calculatedSrri',
];

export const PORTFOLIOS_SUGGESTIONS: PortfolioSearchSuggestionLabel = {
    shareNames: 'common.suggestions.share_names',
    advisors: 'common.suggestions.advisors',
    riskProfile: 'common.suggestions.risk_profile',
    shareCategories: 'common.suggestions.share_categories',
    shareAssetUniverses: 'common.suggestions.share_asset_universes',
    shareInvestGeographies: 'common.suggestions.share_invest_geographies',
    client: 'common.suggestions.client',
    name: 'common.suggestions.portfolio_name',
    assuranceVies: 'common.suggestions.assurance_vies',
};

export const PORTFOLIO_ALERT_PERF = 1;

export const PORTFOLIO_ALERT_RISK_PROFILE = 2;

export const PORTFOLIO_POSITIVE_RISK = [
    'PERF',
    'MADD',
];

export const PORTFOLIO_NEGATIVE_RISK = [];

export const PORTFOLIO_SELECT_PORTFOLIO_PORTFOLIO_LIST_KEY = 'SELECT_PORTFOLIO';

export const MY_PORTFOLIO_UNIVERSE_ID = 1;
