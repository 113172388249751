export enum PortfolioViewModeValue {
    OBJECTIVE = 'OBJECTIVE',
    COMMENTS = 'COMMENTS',
    VIDEO = 'VIDEO',
    CAPITAL_AND_PROFILE = 'CAPITAL_AND_PROFILE',
    RESPONSIBLE_INVESTMENT = 'RESPONSIBLE_INVESTMENT',
    PERFORMANCE_EVOLUTION = 'PERFORMANCE_EVOLUTION',
    RISK_INDICATOR_ANALYSIS = 'RISK_INDICATOR_ANALYSIS',
    AVERAGE_COMPOSITION = 'AVERAGE_COMPOSITION',
    PERFORMANCE_CONTRIBUTION = 'PERFORMANCE_CONTRIBUTION',
    FUND_LIST_AND_DOCUMENTS = 'FUND_LIST_AND_DOCUMENTS',
    ASSURANCE_VIE_ELIGIBILITY = 'ASSURANCE_VIE_ELIGIBILITY',
}
