import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Memoize } from '~/app/core/decorators/memoize.decorators';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { Currency } from '~/app/shared/types/currency/currency.type';
import { Forex } from '~/app/shared/types/forex.type';

@Injectable({
    providedIn: 'root',
})
export class CurrenciesService {
    constructor(
        private http: HttpClient,
        private authFacade: AuthenticationFacade,
    ) {}

    getCurrencies(isDefault: boolean) {
        const languageCode = this.authFacade.getUserLanguageSnapshot();
        return this.getCurrenciesRaw(isDefault, languageCode);
    }

    getForex(code: string) {
        return this.http.get<Readonly<Forex>>(`/currencies/${code}/forex`);
    }

    @Memoize({
        isObservable: true,
        maxSize: 2,
    })
    private getCurrenciesRaw(
        isDefault: boolean,
        _languageCode: string, // keep for memoization
    ) {
        return this.http.get<Readonly<Currency[]>>('/currencies', { params: { isDefault } });
    }
}
