<div
    role="alert"
    class="alert alert--{{ type }} alert--size-{{ size }}"
    [class.alert--radius]="radius"
>
    <div
        role="presentation"
        class="alert__icon-wrapper"
    >
        <eb-icon
            class="login__logo"
            [icon]="icon"
            width="18"
        />
    </div>

    <p class="alert__text">
        {{ text }}
        <ng-content />
    </p>
</div>
