import {
    HttpBackend,
    HttpClient,
    HttpContext,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Moized } from 'moize';
import {
    filter,
    Observable,
    of,
    switchMap,
} from 'rxjs';
import { map } from 'rxjs/operators';

import { NO_INTERCEPTOR_ERROR } from '~/app/core/constants/common.constants';
import { MY_PORTFOLIO_UNIVERSE_ID } from '~/app/core/constants/portfolios.constants';
import { Memoize } from '~/app/core/decorators/memoize.decorators';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { PortfolioUploadStatus } from '~/app/shared/enums/portfolio-upload.enum';
import { createPortfolioForRequest } from '~/app/shared/services/portfolios-utils/portfolios-utils.service';
import { AllocationValues } from '~/app/shared/types/allocation/allocation-values/allocation-values.type';
import { Allocation } from '~/app/shared/types/allocation/allocation.type';
import { CashAllocationToUpdate } from '~/app/shared/types/cash-allocation-to-update.type';
import { FoundCurrencies } from '~/app/shared/types/currency/found-currencies.type';
import { MissingCurrencies } from '~/app/shared/types/currency/missing-currencies.type';
import { WrongCurrencies } from '~/app/shared/types/currency/wrong-currencies.type';
import { DocumentItem } from '~/app/shared/types/document-item.type';
import { ExcelAllocationRequest } from '~/app/shared/types/excel-allocation-request.type';
import { MediaForm } from '~/app/shared/types/media-form.type';
import { MediaS3 } from '~/app/shared/types/media-s3.type';
import { CollectionOfPortfolios } from '~/app/shared/types/portfolio/collection-of-portfolios.type';
import { CreatePortfolio } from '~/app/shared/types/portfolio/create-portfolio.type';
import { PortfolioAllocationStyle } from '~/app/shared/types/portfolio/portfolio-allocation-style.type';
import { PortfolioContext } from '~/app/shared/types/portfolio/portfolio-context.type';
import { PortfolioFrequency } from '~/app/shared/types/portfolio/portfolio-frequency.type';
import { PortfolioMetaData } from '~/app/shared/types/portfolio/portfolio-metadata.type';
import { PortfolioReportingResponse } from '~/app/shared/types/portfolio/portfolio-reporting-response.type';
import { PortfolioRequestUpdate } from '~/app/shared/types/portfolio/portfolio-request-update.type';
import {
    PortfolioSearchSuggestion,
} from '~/app/shared/types/portfolio/portfolio-search-suggestion.type';
import { PortfolioUniverse } from '~/app/shared/types/portfolio/portfolio-universe.type';
import { PortfolioUploadResult } from '~/app/shared/types/portfolio/portfolio-upload-result.type';
import { Portfolio } from '~/app/shared/types/portfolio/portfolio.type';
import { ProjectionRequest } from '~/app/shared/types/projection/projection-request.type';
import { Projection } from '~/app/shared/types/projection/projection.type';
import { SearchQueryBody } from '~/app/shared/types/search/search-query-body.type';
import { ShareAllocationToUpdate } from '~/app/shared/types/share-allocation-to-update.type';
import { ConflictedShares } from '~/app/shared/types/shares/conflicted-shares.type';
import { FoundShares } from '~/app/shared/types/shares/found-shares.type';
import { MissingShares } from '~/app/shared/types/shares/missing-shares.type';
import { ShareCategory } from '~/app/shared/types/shares/share-category.type';
import { WrongShares } from '~/app/shared/types/shares/wrong-shares.type';
import { Video } from '~/app/shared/types/video.type';
import { formatMetadatasPortfolio } from '~/app/shared/utils/metadata/metadata.utils';

@Injectable({
    providedIn: 'root',
})
export class PortfoliosService {
    private httpBase: HttpClient;

    constructor(
        private http: HttpClient,
        private backend: HttpBackend,
        private authFacade: AuthenticationFacade,
    ) {
        this.httpBase = new HttpClient(backend);
    }

    suggestions(query: string, portfolioUniverse: number | undefined) {
        if (query.length <= 2) { return of([]); }

        return this.http.get<Readonly<PortfolioSearchSuggestion[]>>('/portfolios/search/suggestions', {
            params: {
                q: query,
                ...(portfolioUniverse ? { portfolioUniverse } : {}),
            },
        }).pipe(
            filter((response) => response.length > 0),
        );
    }

    getAllocationStyles() {
        const languageCode = this.authFacade.getUserLanguageSnapshot();
        return this.getAllocationStylesRaw(languageCode);
    }

    getPortfolio(id: number): Observable<Readonly<Portfolio>> {
        return this.http.get<Readonly<Portfolio>>(`/portfolios/${id}`);
    }

    getPortfolioVideos(id: number): Observable<Readonly<Video[]>> {
        return this.http.get<Readonly<Video[]>>(`/portfolios/${id}/videos`);
    }

    createPortfolioVideo(id: number, videoParam: MediaForm): Observable<Readonly<Video>> {
        return this.http.post<Readonly<Video>>(`/portfolios/${id}/videos`, videoParam);
    }

    updatePortfolioVideo(id: number, videoId: string, videoParam: MediaForm): Observable<Readonly<Video>> {
        return this.http.put<Readonly<Video>>(`/portfolios/${id}/videos/${videoId}`, videoParam);
    }

    uploadPortfolioVideo(file: File): Observable<Readonly<string>> {
        return this.http.get<Readonly<MediaS3>>('/portfolios/videos/upload')
            .pipe(
                switchMap((s3Document: MediaS3) => this.httpBase.put<Readonly<MediaS3>>(s3Document.presignedUrl, file, {
                    headers: { 'Content-Type': 'video/mp4' },
                })
                    .pipe(
                        map(() => s3Document.fileKey),
                    )),
            );
    }

    deletePortfolioVideo(id: number, videoId: string) {
        return this.http.delete(`/portfolios/${id}/videos/${videoId}`);
    }

    getPortfolioDocuments(id: number): Observable<Readonly<DocumentItem[]>> {
        return this.http.get<Readonly<DocumentItem[]>>(`/portfolios/${id}/documents`);
    }

    createPortfolioDocument(id: number, docParam: MediaForm): Observable<Readonly<DocumentItem>> {
        return this.http.post<Readonly<DocumentItem>>(`/portfolios/${id}/documents`, docParam);
    }

    updatePortfolioDocument(id: number, documentId: string, docParam: MediaForm): Observable<Readonly<DocumentItem>> {
        return this.http.put<Readonly<DocumentItem>>(`/portfolios/${id}/documents/${documentId}`, docParam);
    }

    uploadPortfolioDocument(file: File): Observable<Readonly<string>> {
        return this.http.get<Readonly<MediaS3>>('/portfolios/documents/upload')
            .pipe(
                switchMap((s3Document: MediaS3) => this.httpBase.put<Readonly<MediaS3>>(s3Document.presignedUrl, file, {
                    headers: { 'Content-Type': 'application/pdf' },
                })
                    .pipe(
                        map(() => s3Document.fileKey),
                    )),
            );
    }

    deletePortfolioDocument(id: number, documentId: string) {
        return this.http.delete(`/portfolios/${id}/documents/${documentId}`);
    }

    getLastAllocation(id: number): Observable<Readonly<Allocation>> {
        return this.http.get<Readonly<Allocation>>(`/portfolios/${id}/last-allocation`);
    }

    getLastValuations(portfolioIds: number[]): Observable<Readonly<Allocation[]>> {
        return this.http.get<Readonly<Allocation[]>>('/portfolios/last-valuations', {
            params: {
                portfolioIds,
            },
        });
    }

    updateAllocation(id: number, allocation: {
        shares: ShareAllocationToUpdate[],
        cashes: CashAllocationToUpdate[],
    }) {
        return this.http.post<Readonly<Allocation>>(`/portfolios/${id}/allocations`, allocation);
    }

    getComposition(id: number, period: string, endDate: string | null = null): Observable<Readonly<AllocationValues>> {
        return this.http.get<Readonly<AllocationValues>>(`/portfolios/${id}/composition`, {
            params: {
                period,
                ...(endDate ? { endDate } : {}),
            },
        });
    }

    getPerformancesContribution(id: number, period: string, endDate: string | null = null): Observable<Readonly<AllocationValues>> {
        return this.http.get<Readonly<AllocationValues>>(`/performances/portfolios/${id}/contribution`, {
            params: {
                period,
                ...(endDate ? { endDate } : {}),
            },
        });
    }

    updatePortfolio(id: number, fields: Partial<PortfolioRequestUpdate>) {
        return this.http.patch<Readonly<Portfolio>>(`/portfolios/${id}`, fields);
    }

    deletePortfolio(id: number) {
        return this.http.delete(`/portfolios/${id}`);
    }

    deletePortfolios(ids: number[]): Observable<boolean> {
        if (!ids.length) {
            return of(false);
        }
        const [first, ...rest] = ids;
        return this.deletePortfolio(first)
            .pipe(
                switchMap(() => {
                    if (rest.length) {
                        return this.deletePortfolios(rest);
                    }
                    return of(true);
                }),
            );
    }

    getContexts() {
        const languageCode = this.authFacade.getUserLanguageSnapshot();
        return this.getContextsRaw(languageCode);
    }

    getFrequencies() {
        const languageCode = this.authFacade.getUserLanguageSnapshot();
        return this.getFrequenciesRaw(languageCode);
    }

    getUniverses() {
        return this.http.get<Readonly<PortfolioUniverse[]>>('/portfolio-universes');
    }

    getMetaData(refreshCache: boolean = false) {
        if (refreshCache) {
            // eslint-disable-next-line @typescript-eslint/unbound-method
            const moizedFn = this.getMetaDataRaw as Moized;
            moizedFn.clear();
        }

        return this.getMetaDataRaw();
    }

    getReporting(id: number, period: string, endDate: string | null = null): Observable<PortfolioReportingResponse> {
        return this.http.get<Readonly<PortfolioReportingResponse>>(`/portfolios/${id}/reporting`, {
            params: {
                period,
                ...(endDate ? { endDate } : {}),
            },
        });
    }

    getCompatibleCategories(id: number) {
        return this.http.get<Readonly<ShareCategory[]>>(`/portfolios/${id}/compatible-categories`);
    }

    createPortfolio(portfolio: CreatePortfolio) {
        return this.http.post<Readonly<Portfolio>>('/portfolios', createPortfolioForRequest(portfolio), { context: new HttpContext().set(NO_INTERCEPTOR_ERROR, true) });
    }

    projection(payload: ProjectionRequest): Observable<Readonly<Projection>> {
        return this.http.post<Readonly<Projection>>('/portfolios/projection', payload);
    }

    upload(file: File) {
        const formData = new FormData();

        formData.append('file', file);

        return this.http.post<Readonly<PortfolioUploadResult>>('/portfolios/upload', formData)
            .pipe(
                map((results) => (
                    {
                        conflictedShares: results.conflictedShares.map(
                            (shares) => shares.map((item): ConflictedShares => ({
                                ...item, status: PortfolioUploadStatus.CONFLICTED,
                            })),
                        ),
                        foundShares: results.foundShares.map((item): FoundShares => ({
                            ...item, status: PortfolioUploadStatus.FOUND,
                        })),
                        missingShares: results.missingShares.map((item): MissingShares => ({
                            ...item, status: PortfolioUploadStatus.MISSING,
                        })),
                        wrongFormattedShares: results.wrongFormattedShares.map((item): WrongShares => ({
                            ...item, status: PortfolioUploadStatus.WRONG,
                        })),
                        foundCurrencies: results.foundCurrencies.map((item): FoundCurrencies => ({
                            ...item, status: PortfolioUploadStatus.FOUND,
                        })),
                        missingCurrencies: results.missingCurrencies.map((item): MissingCurrencies => ({
                            ...item, status: PortfolioUploadStatus.MISSING,
                        })),
                        wrongFormattedCurrencies: results.wrongFormattedCurrencies.map((item): WrongCurrencies => ({
                            ...item, status: PortfolioUploadStatus.WRONG,
                        })),
                    }
                )),
            );
    }

    excelAllocationsValidate(params: ExcelAllocationRequest) {
        return this.http.post<Readonly<Allocation>>('/portfolios/excel-allocations/validate', params);
    }

    search(query: SearchQueryBody) {
        return this.http.post<Readonly<CollectionOfPortfolios>>('/portfolios/search', query).pipe(
            map((result) => ({
                ...result,
                ...(result.metadata ? { metadata: formatMetadatasPortfolio(result.metadata) } : {}),
            })),
        );
    }

    hasPortfolio() {
        return this.search({
            fields: 'name,',
            q: '',
            filters: [],
            sorts: [],
            portfolioUniverse: MY_PORTFOLIO_UNIVERSE_ID,
            subSegmentation: 'ALL',
            includeMetadata: false,
            startAt: 0,
            size: 1,
            requestId: '',
        })
            .pipe(
                map((result) => !!result.values?.length),
            );
    }

    getAdvicePortfolio(id: number): Observable<Readonly<Portfolio>> {
        return this.http.get<Readonly<Portfolio>>(`/portfolios/${id}/advice-portfolio`);
    }

    updateAdvicePortfolio(id: number, portfolioAdviceId: number): Observable<Readonly<Portfolio>> {
        return this.http.post<Readonly<Portfolio>>(`/portfolios/${id}/advice-portfolio`, { portfolioAdviceId });
    }

    deleteAdvicePortfolio(id: number) {
        return this.http.delete(`/portfolios/${id}/advice-portfolio`);
    }

    @Memoize({
        isObservable: true,
    })
    private getAllocationStylesRaw(
        _languageCode: string,
    ) {
        return this.http.get<Readonly<PortfolioAllocationStyle[]>>('/portfolios/allocation-styles');
    }

    @Memoize({
        isObservable: true,
    })
    private getContextsRaw(
        _languageCode: string,
    ) {
        return this.http.get<Readonly<PortfolioContext[]>>('/portfolios/contexts');
    }

    @Memoize({
        isObservable: true,
    })
    private getFrequenciesRaw(
        _languageCode: string,
    ) {
        return this.http.get<Readonly<PortfolioFrequency[]>>('/portfolios/frequencies');
    }

    @Memoize({
        isObservable: true,
    })
    private getMetaDataRaw() {
        return this.http.get<Readonly<PortfolioMetaData>>('/portfolios/search/metadata').pipe(
            map((metadata) => formatMetadatasPortfolio(metadata)),
        );
    }
}
