export enum ShareViewModeValue {
    INVESTMENT_OBJECTIVE = 'INVESTMENT_OBJECTIVE',
    VIDEO = 'VIDEO',
    RISK_RETURN_NEAREST = 'RISK_RETURN_NEAREST',
    ROLLING_PERIOD_OVERVIEW = 'ROLLING_PERIOD_OVERVIEW',
    ROLLING_PERIOD_ANALYSIS = 'ROLLING_PERIOD_ANALYSIS',
    ROLLING_PERIOD_RECOVERY_TIME = 'ROLLING_PERIOD_RECOVERY_TIME',
    CALENDAR_PERFORMANCE = 'CALENDAR_PERFORMANCE',
    MARKET_CYCLE_ANALYSIS = 'MARKET_CYCLE_ANALYSIS',
    MARKET_CYCLE_PERFORMANCE = 'MARKET_CYCLE_PERFORMANCE',
    LEGAL_DOCUMENTS = 'LEGAL_DOCUMENTS',
    ASSURANCE_VIE_ELIGIBILITY = 'ASSURANCE_VIE_ELIGIBILITY',
}
