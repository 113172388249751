import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
    Action,
    createSelector,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';
import { omit } from 'ramda';
import {
    of,
} from 'rxjs';
import {
    switchMap,
    tap,
} from 'rxjs/operators';

import { PORTFOLIO_DETAIL_REPORTING_PERIODS } from '~/app/core/constants/portfolio-detail.constants';
import { SHARE_DETAIL_REPORTING_PERIODS } from '~/app/core/constants/share-detail.constants';
import { AlertsService } from '~/app/core/services/api/alerts/alerts.service';
import { ComparisonListsService } from '~/app/core/services/api/comparison-lists/comparison-lists.service';
import { CurrenciesService } from '~/app/core/services/api/currencies/currencies.service';
import { DocumentsService } from '~/app/core/services/api/documents/documents.service';
import { GeographiesService } from '~/app/core/services/api/geographies/geographies.service';
import { MarketEventsService } from '~/app/core/services/api/market-events/market-events.service';
import { PortfoliosService } from '~/app/core/services/api/portfolios/portfolios.service';
import { RiskIndicatorsService } from '~/app/core/services/api/risk-indicators/risk-indicators.service';
import { RiskProfilesService } from '~/app/core/services/api/risk-profiles/risk-profiles.service';
import { SavedAllocationConstraintsService } from '~/app/core/services/api/saved-allocation-constraints/saved-allocation-constraints.service';
import { SrrisService } from '~/app/core/services/api/srris/srris.service';
import { TenantsService } from '~/app/core/services/api/tenants/tenants.service';
import { VideosService } from '~/app/core/services/api/videos/videos.service';
import { ConsentFlowStates } from '~/app/shared/enums/consent/consent-flow-state.enum';
import { Period as PeriodEnum } from '~/app/shared/enums/period.enum';
import {
    MarketCycle,
} from '~/app/shared/interfaces/RiskIndicator';
import { ComparisonListModel } from '~/app/shared/types/api/comparison-list-model.type';
import { Menu } from '~/app/shared/types/api/menu.type';
import { Currency } from '~/app/shared/types/currency/currency.type';
import { Forex } from '~/app/shared/types/forex.type';
import { Geography } from '~/app/shared/types/geography.type';
import { ListItemAlt } from '~/app/shared/types/list-item-alt.type';
import { ListItem } from '~/app/shared/types/list-item.type';
import { MarketEvent } from '~/app/shared/types/market-event.type';
import { Operation } from '~/app/shared/types/operation.type';
import { Period } from '~/app/shared/types/period.type';
import { PortfolioAllocationStyle } from '~/app/shared/types/portfolio/portfolio-allocation-style.type';
import { RiskProfile } from '~/app/shared/types/risk-profile.type';
import { SavedAllocationConstraint } from '~/app/shared/types/saved-allocation-constraint/saved-allocation-constraint.type';
import { SrriVolatility } from '~/app/shared/types/srri-volatility.type';

import {
    AddEntitiesToComparisonListAction,
    AddOperationAction,
    ConsentAction,
    CreateComparisonListAction,
    CreateComparisonListSuccessAction,
    DeleteComparisonListAction,
    DeleteOperationAction,
    GetAlertsAction,
    GetAllocationStylesAction,
    GetComparisonListsAction,
    GetContextsAction,
    GetCurrenciesAction,
    GetDocumentCategoriesAction,
    GetForexAction,
    GetFrequenciesAction,
    GetGeographiesAction,
    GetMarketCyclesAction,
    GetMarketEventsAction,
    GetMenusAction,
    GetPeriodsAction,
    GetRiskProfilesAction,
    GetSavedAllocationConstraintsAction,
    GetSrriVolatilitiesAction,
    GetVideoCategoriesAction,
    HasPortfoliosAction,
    RenameComparisonListAction,
    ReplaceComparisonListEntitiesAction,
    UpdateAccountServicerIdAction,
    UpdateDisplayLeftColumnPortfolioAction,
    UpdateDisplayLeftColumnShareAction,
    UpdateIsFreemiumOnboardingVisibleAction,
    UpdateIsIntroductionEnhancementSkippedAction,
    UpdateIsIntroductionOptimalCreationSkippedAction,
    UpdateLastCheckSessionDateAction,
    UpdateSimulationTopFundsLatestIndicatorAction,
} from '../application-action/application.actions';

export interface CategoryModel {
    code:string,
    name: string,
}

export interface ApplicationStateModel {
    accountServicerId?: string,
    allocationStyles: Array<PortfolioAllocationStyle>,
    comparisonLists: Array<ComparisonListModel>,
    contexts: Array<ListItem>,
    currencies: Array<Currency>,
    consentState: ConsentFlowStates,
    defaultCurrencies: Array<Currency>,
    displayLeftColumnPortfolio: boolean,
    displayLeftColumnShare: boolean,
    documentPortfolioCategories: Array<CategoryModel>,
    documentShareCategories: Array<CategoryModel>,
    forex: {
        [code: string]: Forex,
    },
    frequencies: Array<ListItemAlt>,
    geographies: Geography[],
    hasPortfolios: boolean | undefined,
    isFreemiumOnboardingVisible: boolean,
    lastCheckSessionDate: string | null,
    marketCycles: MarketCycle[],
    marketEvents: MarketEvent[],
    menus: Menu[],
    operations: { [key: string]: Operation<unknown> },
    periods: Array<Period>,
    portfolioAllocation: {
        isIntroductionEnhancementSkipped: boolean,
        isIntroductionOptimalCreationSkipped: boolean,
    },
    portfolioEnhancement: {
        isIntroductionSkipped: boolean,
    },
    riskProfiles: RiskProfile[],
    savedAllocationConstraints: SavedAllocationConstraint[],
    simulationTopFundsLatestIndicator: {
        code: string,
        period: PeriodEnum,
    } | null,
    srriVolatility: SrriVolatility[],
    videoPortfolioCategories: Array<CategoryModel>,
    videoShareCategories: Array<CategoryModel>,
}

const defaults = {
    isFreemiumOnboardingVisible: false,
    allocationStyles: [],
    comparisonLists: [],
    contexts: [],
    consentState: 'IDLE' as ConsentFlowStates,
    currencies: [],
    documentShareCategories: [],
    documentPortfolioCategories: [],
    defaultCurrencies: [],
    forex: {},
    frequencies: [],
    geographies: [],
    hasPortfolios: undefined,
    marketEvents: [],
    marketCycles: [],
    menus: [],
    operations: {},
    periods: [],
    riskProfiles: [],
    srriVolatility: [],
    savedAllocationConstraints: [],
    videoShareCategories: [],
    videoPortfolioCategories: [],
    displayLeftColumnPortfolio: false,
    displayLeftColumnShare: false,
    simulationTopFundsLatestIndicator: null,
    lastCheckSessionDate: null,
    portfolioAllocation: {
        isIntroductionEnhancementSkipped: false,
        isIntroductionOptimalCreationSkipped: false,
    },
    portfolioEnhancement: {
        isIntroductionSkipped: false,
    },
};

@State<ApplicationStateModel>({
    name: 'application',
    defaults,
})
@Injectable()
export class ApplicationState {
    constructor(
        private alertsService: AlertsService,
        private comparisonListsService: ComparisonListsService,
        private currenciesService: CurrenciesService,
        private documentService: DocumentsService,
        private geographiesService: GeographiesService,
        private portfoliosService: PortfoliosService,
        private riskIndicatorsService: RiskIndicatorsService,
        private marketEventsService: MarketEventsService,
        private riskProfilesService: RiskProfilesService,
        private srrisService: SrrisService,
        private savedAllocationConstraintsService: SavedAllocationConstraintsService,
        private videoService: VideosService,
        private tenantsService: TenantsService,
        private transloco: TranslocoService,
    ) {}

    static getOperation(key: string) {
        return createSelector([ApplicationState], (state: ApplicationStateModel) => state.operations[key]);
    }

    @Selector()
    static getAllocationStyles(state: ApplicationStateModel) {
        return state.allocationStyles;
    }

    @Selector()
    static getComparisonLists(state: ApplicationStateModel) {
        return state.comparisonLists;
    }

    @Selector()
    static getContexts(state: ApplicationStateModel) {
        return state.contexts;
    }

    @Selector()
    static getCurrencies(state: ApplicationStateModel) {
        return state.currencies;
    }

    @Selector()
    static getDefaultCurrencies(state: ApplicationStateModel) {
        return state.defaultCurrencies;
    }

    @Selector()
    static getConsentState(state: ApplicationStateModel) : ConsentFlowStates {
        return state.consentState;
    }

    @Selector()
    static getDocumentShareCategories(state: ApplicationStateModel) {
        return state.documentShareCategories.map((category) => ({
            key: category.code,
            label: category.name,
        }));
    }

    @Selector()
    static getDocumentPortfolioCategories(state: ApplicationStateModel) {
        return state.documentPortfolioCategories.map((category) => ({
            key: category.code,
            label: category.name,
        }));
    }

    @Selector()
    static getForex(state: ApplicationStateModel) {
        return state.forex;
    }

    @Selector()
    static getFrequencies(state: ApplicationStateModel) {
        return state.frequencies;
    }

    @Selector()
    static getGeographies(state: ApplicationStateModel) {
        return state.geographies;
    }

    @Selector()
    static getMarketEvents(state: ApplicationStateModel) {
        return state.marketEvents;
    }

    @Selector()
    static getPeriods(state: ApplicationStateModel) {
        return state.periods;
    }

    @Selector()
    static getRiskProfiles(state: ApplicationStateModel) {
        return state.riskProfiles;
    }

    @Selector()
    static getAllPeriods(state: ApplicationStateModel) {
        return state.periods;
    }

    @Selector()
    static getSpecificPeriodsForShare(state: ApplicationStateModel) {
        return state.periods.filter((period) => SHARE_DETAIL_REPORTING_PERIODS.includes(period.code));
    }

    @Selector()
    static getSpecificPeriodsForPortfolio(state: ApplicationStateModel) {
        return state.periods.filter((period) => PORTFOLIO_DETAIL_REPORTING_PERIODS.includes(period.code));
    }

    @Selector()
    static getVideosShareCategories(state: ApplicationStateModel) {
        return state.videoShareCategories;
    }

    @Selector()
    static getVideosPortfolioCategories(state: ApplicationStateModel) {
        return state.videoPortfolioCategories;
    }

    @Selector()
    static getDisplayLeftColumnPortfolio(state: ApplicationStateModel) : boolean {
        return state.displayLeftColumnPortfolio;
    }

    @Selector()
    static getDisplayLeftColumnShare(state: ApplicationStateModel) : boolean {
        return state.displayLeftColumnShare;
    }

    @Selector()
    static getSrriVolatilities(state: ApplicationStateModel) : SrriVolatility[] {
        return state.srriVolatility;
    }

    @Selector()
    static getSavedAllocationConstraints(state: ApplicationStateModel): SavedAllocationConstraint[] {
        return state.savedAllocationConstraints;
    }

    @Selector()
    static getSimulationTopFundsLatestIndicator(state: ApplicationStateModel): { code: string, period: PeriodEnum } | null {
        return state.simulationTopFundsLatestIndicator;
    }

    @Selector()
    static getIsFreemiumOnboardingVisible(state: ApplicationStateModel) {
        return state.isFreemiumOnboardingVisible;
    }

    @Selector()
    static getMenus(state: ApplicationStateModel): Menu[] {
        return state.menus;
    }

    @Selector()
    static getLastCheckSessionDate(state: ApplicationStateModel) : string | null {
        return state.lastCheckSessionDate;
    }

    @Selector()
    static getHasPortfolios(state: ApplicationStateModel) : boolean | undefined {
        return state.hasPortfolios;
    }

    @Selector()
    static getIsIntroductionEnhancementSkipped(state: ApplicationStateModel): boolean {
        return state.portfolioAllocation.isIntroductionEnhancementSkipped;
    }

    @Selector()
    static getIsIntroductionOptimalCreationSkipped(state: ApplicationStateModel): boolean {
        return state.portfolioAllocation.isIntroductionOptimalCreationSkipped;
    }

    @Selector()
    static getAccountServicerId(state: ApplicationStateModel) {
        return state.accountServicerId;
    }

    @Action(AddOperationAction)
    addOperation({ patchState }: StateContext<ApplicationStateModel>, action: AddOperationAction<unknown>) {
        patchState({
            operations: {
                [action.id]: action.operation,
            },
        });
    }

    @Action(ConsentAction)
    updateIsConsentGiven({ patchState } : StateContext<ApplicationStateModel>, action: ConsentAction) {
        patchState({
            consentState: action.consentState,
        });
    }

    @Action(UpdateAccountServicerIdAction)
    updateAccountServicerIdAction({ patchState } : StateContext<ApplicationStateModel>, action: UpdateAccountServicerIdAction) {
        patchState({
            accountServicerId: action.accountServicerId,
        });
    }

    @Action(AddEntitiesToComparisonListAction)
    addEntitiesToComparisonList({ getState, patchState }: StateContext<ApplicationStateModel>, action: AddEntitiesToComparisonListAction) {
        return this.comparisonListsService.addEntitiesToComparisonList(action.id, action.entities)
            .pipe(
                switchMap(() => this.comparisonListsService.getComparisonList(action.id)),
                tap((updatedList) => {
                    const state = getState();

                    patchState({
                        comparisonLists: state.comparisonLists.map((item) => {
                            if (item.id === updatedList.id) {
                                return updatedList;
                            }
                            return item;
                        }),
                    });
                }),
            );
    }

    @Action(CreateComparisonListAction)
    createComparisonList({ getState, patchState, dispatch }: StateContext<ApplicationStateModel>, action: CreateComparisonListAction) {
        return this.comparisonListsService.createComparisonList(action.name)
            .pipe(
                tap((created) => {
                    const state = getState();

                    patchState({
                        comparisonLists: [
                            created,
                            ...state.comparisonLists,
                        ],
                    });

                    dispatch(new AddEntitiesToComparisonListAction(created.id, action.entities));
                    dispatch(new CreateComparisonListSuccessAction(created));
                }),
            );
    }

    @Action(ReplaceComparisonListEntitiesAction)
    replaceComparisonListEntities({ dispatch }: StateContext<ApplicationStateModel>, action: ReplaceComparisonListEntitiesAction) {
        return this.comparisonListsService.getComparisonListEntities(action.id)
            .pipe(
                switchMap((entities) => {
                    if (entities.length > 0) {
                        return this.comparisonListsService.deleteEntitiesToComparisonList(action.id, entities);
                    }
                    return of(true);
                }),
                tap(() => {
                    dispatch(new AddEntitiesToComparisonListAction(action.id, action.entities));
                }),
            );
    }

    @Action(RenameComparisonListAction)
    renameComparisonList({ getState, patchState }: StateContext<ApplicationStateModel>, action: RenameComparisonListAction) {
        return this.comparisonListsService.renameComparisonList(action.id, action.name)
            .pipe(
                tap(() => {
                    const state = getState();

                    patchState({
                        comparisonLists: state.comparisonLists.map((item) => ({
                            ...item,
                            name: item.id === action.id ? action.name : item.name,
                        })),
                    });
                }),
            );
    }

    @Action(DeleteComparisonListAction)
    deleteComparisonList({ getState, patchState }: StateContext<ApplicationStateModel>, action: DeleteComparisonListAction) {
        return this.comparisonListsService.deleteComparisonList(action.id)
            .pipe(
                tap(() => {
                    const state = getState();

                    patchState({
                        comparisonLists: state.comparisonLists.filter((item) => item.id !== action.id),
                    });
                }),
            );
    }

    @Action(DeleteOperationAction)
    deleteOperation({ patchState, getState }: StateContext<ApplicationStateModel>, action: DeleteOperationAction) {
        const state = getState();
        patchState({
            operations: {
                ...omit([action.id], state.operations),
            },
        });
    }

    @Action(GetAlertsAction)
    getAlerts() {
        return this.alertsService.getAlertActions();
    }

    @Action(GetAllocationStylesAction)
    getAllocationStyles({ patchState } : StateContext<ApplicationStateModel>) {
        return this.portfoliosService.getAllocationStyles().pipe(tap((result) => {
            patchState({
                allocationStyles: [...result],
            });
        }));
    }

    @Action(GetComparisonListsAction)
    getComparisonLists({ patchState } : StateContext<ApplicationStateModel>) {
        return this.comparisonListsService.getAllComparisonLists()
            .pipe(
                tap((comparisonLists) => {
                    patchState({
                        comparisonLists: comparisonLists.values,
                    });
                }),
            );
    }

    @Action(GetContextsAction)
    getContexts({ patchState } : StateContext<ApplicationStateModel>) {
        return this.portfoliosService.getContexts().pipe(tap((result) => {
            patchState({
                contexts: [...result],
            });
        }));
    }

    @Action(GetCurrenciesAction)
    getCurrencies({ patchState } : StateContext<ApplicationStateModel>, action: GetCurrenciesAction) {
        return this.currenciesService.getCurrencies(action.isDefault).pipe(tap((result) => {
            patchState(
                action.isDefault
                    ? { defaultCurrencies: [...result] }
                    : { currencies: [...result] },
            );
        }));
    }

    @Action(GetDocumentCategoriesAction)
    getDocumentShareCategories({ patchState } : StateContext<ApplicationStateModel>, action: GetDocumentCategoriesAction) {
        return this.documentService.getDocumentCategories(action.module).pipe(
            tap((results) => {
                patchState({
                    ...(action.module === 'FUND' ? { documentShareCategories: [
                        {
                            code: 'all',
                            name: this.transloco.translate('common.all'),
                        },
                        ...results,
                    ] } : {
                        documentPortfolioCategories: [
                            {
                                code: 'all',
                                name: this.transloco.translate('common.all'),
                            },
                            ...results,
                        ],
                    }),
                });
            }),
        );
    }

    @Action(GetForexAction)
    getForex({ getState, patchState } : StateContext<ApplicationStateModel>, action: GetForexAction) {
        return this.currenciesService.getForex(action.code).pipe(tap((result) => {
            patchState({
                forex: {
                    ...getState().forex,
                    [result.id]: result,
                },
            });
        }));
    }

    @Action(GetFrequenciesAction)
    getFrequencies({ patchState } : StateContext<ApplicationStateModel>) {
        return this.portfoliosService.getFrequencies().pipe(tap((result) => {
            patchState({
                frequencies: [...result],
            });
        }));
    }

    @Action(GetGeographiesAction)
    getGeographies({ patchState } : StateContext<ApplicationStateModel>) {
        return this.geographiesService.getGeographies().pipe(tap((result) => {
            patchState({
                geographies: [...result],
            });
        }));
    }

    @Action(GetMarketEventsAction)
    getMarketEventsAction({ patchState } : StateContext<ApplicationStateModel>) {
        return this.marketEventsService.getMarketEvents().pipe(tap((result) => {
            patchState({
                marketEvents: [...result],
            });
        }));
    }

    @Action(GetMarketCyclesAction)
    getMarketCycles({ patchState } : StateContext<ApplicationStateModel>) {
        return this.riskIndicatorsService.getMarketCycles().pipe(tap((result) => {
            patchState({
                marketCycles: [...result],
            });
        }));
    }

    @Action(GetPeriodsAction)
    getPeriods({ patchState } : StateContext<ApplicationStateModel>) {
        return this.riskIndicatorsService.getPeriods().pipe(tap((results) => {
            patchState({
                periods: [...results],
            });
        }));
    }

    @Action(GetRiskProfilesAction)
    getRiskProfiles({ patchState } : StateContext<ApplicationStateModel>) {
        return this.riskProfilesService.getRiskProfiles().pipe(tap((result) => {
            patchState({
                riskProfiles: [...result],
            });
        }));
    }

    @Action(GetVideoCategoriesAction)
    getVideoCategories({ patchState } : StateContext<ApplicationStateModel>, action: GetVideoCategoriesAction) {
        return this.videoService.getVideoCategories(action.module).pipe(tap((results) => {
            patchState({
                ...(action.module === 'FUND' ? { videoShareCategories: [
                    {
                        code: 'all',
                        name: 'All',
                    },
                    ...results,
                ] } : {
                    videoPortfolioCategories: [
                        {
                            code: 'all',
                            name: 'All',
                        },
                        ...results,
                    ],
                }),
            });
        }));
    }

    @Action(GetSrriVolatilitiesAction)
    getSrriVolatilities({ patchState } : StateContext<ApplicationStateModel>) {
        return this.srrisService.getSrriVolatilities().pipe(tap((result) => {
            patchState({
                srriVolatility: [...result],
            });
        }));
    }

    @Action(GetSavedAllocationConstraintsAction)
    getSavedAllocationConstraints({ patchState }: StateContext<ApplicationStateModel>) {
        return this.savedAllocationConstraintsService.getSavedAllocationConstraints()
            .pipe(
                tap((result) => {
                    patchState({
                        savedAllocationConstraints: result,
                    });
                }),
            );
    }

    @Action(UpdateDisplayLeftColumnPortfolioAction)
    updateDisplayLeftColumnPortfolio({ patchState } : StateContext<ApplicationStateModel>, action: UpdateDisplayLeftColumnPortfolioAction) {
        patchState({
            displayLeftColumnPortfolio: action.displayLeftColumn,
        });
    }

    @Action(UpdateDisplayLeftColumnShareAction)
    updateDisplayLeftColumnShare({ patchState } : StateContext<ApplicationStateModel>, action: UpdateDisplayLeftColumnShareAction) {
        patchState({
            displayLeftColumnShare: action.displayLeftColumn,
        });
    }

    @Action(UpdateSimulationTopFundsLatestIndicatorAction)
    updateSimulationTopFundsLatestIndicator({ patchState } : StateContext<ApplicationStateModel>, action: UpdateSimulationTopFundsLatestIndicatorAction) {
        patchState({
            simulationTopFundsLatestIndicator: action.indicator,
        });
    }

    @Action(UpdateIsFreemiumOnboardingVisibleAction)
    updateIsFreemiumOnboardingVisible({ patchState } : StateContext<ApplicationStateModel>, action: UpdateIsFreemiumOnboardingVisibleAction) {
        patchState({
            isFreemiumOnboardingVisible: action.isFreemiumOnboardingVisible,
        });
    }

    @Action(UpdateLastCheckSessionDateAction)
    updateLastCheckSessionDate({ patchState } : StateContext<ApplicationStateModel>, action: UpdateLastCheckSessionDateAction) {
        patchState({
            lastCheckSessionDate: action.lastCheckSessionDate,
        });
    }

    @Action(GetMenusAction)
    getMenus({ patchState } : StateContext<ApplicationStateModel>) {
        return this.tenantsService.getMenus().pipe(tap((result) => {
            patchState({
                menus: [...result],
            });
        }));
    }

    @Action(HasPortfoliosAction)
    hasPortfolios({ patchState } : StateContext<ApplicationStateModel>, action: HasPortfoliosAction) {
        patchState({
            hasPortfolios: action.hasPortfolios,
        });
    }

    @Action(UpdateIsIntroductionEnhancementSkippedAction)
    updateIsIntroductionEnhancementSkipped({ getState, patchState }: StateContext<ApplicationStateModel>,
        action: UpdateIsIntroductionEnhancementSkippedAction) {
        patchState({
            portfolioAllocation: {
                ...getState().portfolioAllocation,
                isIntroductionEnhancementSkipped: action.isSkipped,
            },
        });
    }

    @Action(UpdateIsIntroductionOptimalCreationSkippedAction)
    updateIsIntroductionOptimalCreationSkipped({ getState, patchState }: StateContext<ApplicationStateModel>,
        action: UpdateIsIntroductionOptimalCreationSkippedAction) {
        patchState({
            portfolioAllocation: {
                ...getState().portfolioAllocation,
                isIntroductionOptimalCreationSkipped: action.isSkipped,
            },
        });
    }
}
