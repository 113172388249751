export enum IndicatorType {
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
    PERCENTAGE = 'PERCENTAGE',
    RISK_PROFILE = 'RISK_PROFILE',
    NOTATION = 'NOTATION',
    RISK_INDICATOR = 'RISK_INDICATOR',
    ACTIVE_RISK = 'ACTIVE_RISK',
    EMPTY = 'EMPTY',
    ALERT = 'ALERT',
    PROJECTION_PERF = 'PROJECTION_PERF',
    SIMULATION_SCENARIO = 'SIMULATION_SCENARIO',
}
