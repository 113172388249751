import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Memoize } from '~/app/core/decorators/memoize.decorators';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { RiskProfile } from '~/app/shared/types/risk-profile.type';

@Injectable({
    providedIn: 'root',
})
export class RiskProfilesService {
    constructor(
        private http: HttpClient,
        private authFacade: AuthenticationFacade,
    ) {}

    getRiskProfiles() {
        const languageCode = this.authFacade.getUserLanguageSnapshot();
        return this.getRiskProfilesRaw(languageCode);
    }

    @Memoize({
        isObservable: true,
    })
    private getRiskProfilesRaw(
        _languageCode: string, // keep for memoization
    ) {
        return this.http.get<Readonly<RiskProfile[]>>('/risk-profiles');
    }
}
