import { Injectable } from '@angular/core';
import {
    Actions,
    ofActionDispatched,
    ofActionSuccessful,
    Select,
    Store,
} from '@ngxs/store';
import {
    forkJoin,
    Observable,
    of,
    switchMap,
} from 'rxjs';
import {
    map,
    tap,
} from 'rxjs/operators';

import { PortfoliosService } from '~/app/core/services/api/portfolios/portfolios.service';
import {
    AddEntitiesToComparisonListAction,
    AddOperationAction,
    ConsentAction,
    CreateComparisonListAction,
    CreateComparisonListSuccessAction,
    DeleteComparisonListAction,
    DeleteOperationAction,
    GetAllocationStylesAction,
    GetComparisonListsAction,
    GetContextsAction,
    GetCurrenciesAction,
    GetDocumentCategoriesAction,
    GetFrequenciesAction,
    GetGeographiesAction,
    GetMarketCyclesAction,
    GetMenusAction,
    GetPeriodsAction,
    GetRiskProfilesAction,
    GetSavedAllocationConstraintsAction,
    GetSrriVolatilitiesAction,
    GetVideoCategoriesAction,
    HasPortfoliosAction,
    RenameComparisonListAction,
    ReplaceComparisonListEntitiesAction,
    UpdateAccountServicerIdAction,
    UpdateDisplayLeftColumnPortfolioAction,
    UpdateDisplayLeftColumnShareAction,
    UpdateIsFreemiumOnboardingVisibleAction,
    UpdateIsIntroductionEnhancementSkippedAction,
    UpdateIsIntroductionOptimalCreationSkippedAction,
    UpdateLastCheckSessionDateAction,
    UpdateSimulationTopFundsLatestIndicatorAction,
} from '~/app/core/state/application/application-action/application.actions';
import {
    ApplicationState,
} from '~/app/core/state/application/application-state/application.state';
import { AuthorisationFacade } from '~/app/core/state/authorisation/authorisation.facade';
import { ConsentFlowStates } from '~/app/shared/enums/consent/consent-flow-state.enum';
import { Period as PeriodEnum } from '~/app/shared/enums/period.enum';
import { ComparisonListModel } from '~/app/shared/types/api/comparison-list-model.type';
import { Menu } from '~/app/shared/types/api/menu.type';
import { ReferenceModel } from '~/app/shared/types/api/reference-model.type';
import { Currency } from '~/app/shared/types/currency/currency.type';
import { Forex } from '~/app/shared/types/forex.type';
import { Geography } from '~/app/shared/types/geography.type';
import { ListItemAlt } from '~/app/shared/types/list-item-alt.type';
import { ListItem } from '~/app/shared/types/list-item.type';
import { Operation } from '~/app/shared/types/operation.type';
import { Period } from '~/app/shared/types/period.type';
import { PortfolioAllocationStyle } from '~/app/shared/types/portfolio/portfolio-allocation-style.type';
import { RiskProfile } from '~/app/shared/types/risk-profile.type';
import {
    SavedAllocationConstraint,
} from '~/app/shared/types/saved-allocation-constraint/saved-allocation-constraint.type';
import { SrriVolatility } from '~/app/shared/types/srri-volatility.type';
import { TabItem } from '~/app/shared/types/tab-item.type';
import { VideoCategory } from '~/app/shared/types/video-category.type';

@Injectable({
    providedIn: 'root',
})
export class ApplicationFacade {
    @Select(ApplicationState.getRiskProfiles)
    public riskProfiles$!: Observable<RiskProfile[]>;

    @Select(ApplicationState.getForex)
    public forex$!: Observable<{ [index: string]: Forex }>;

    @Select(ApplicationState.getDefaultCurrencies)
    public defaultCurrencies$!: Observable<Currency[]>;

    @Select(ApplicationState.getCurrencies)
    public currencies$!: Observable<Currency[]>;

    @Select(ApplicationState.getAllPeriods)
    public allPeriods$!: Observable<Period[]>;

    @Select(ApplicationState.getSpecificPeriodsForShare)
    public specificPeriodsForShare$!: Observable<Period[]>;

    @Select(ApplicationState.getSpecificPeriodsForPortfolio)
    public specificPeriodsForPortfolio$!: Observable<Period[]>;

    @Select(ApplicationState.getVideosShareCategories)
    public filtersVideoShare$?: Observable<VideoCategory[]>;

    @Select(ApplicationState.getDocumentShareCategories)
    public filtersDocumentShare$?: Observable<TabItem[]>;

    @Select(ApplicationState.getVideosPortfolioCategories)
    public filtersVideoPortfolio$?: Observable<VideoCategory[]>;

    @Select(ApplicationState.getDocumentPortfolioCategories)
    public filtersDocumentPortfolio$?: Observable<TabItem[]>;

    @Select(ApplicationState.getDisplayLeftColumnPortfolio)
    public displayLeftColumnPortfolio$?: Observable<boolean>;

    @Select(ApplicationState.getDisplayLeftColumnShare)
    public displayLeftColumnShare$?: Observable<boolean>;

    @Select(ApplicationState.getSrriVolatilities)
    public srriVolatilities$!: Observable<SrriVolatility[]>;

    @Select(ApplicationState.getConsentState)
    public consentState$?: Observable<ConsentFlowStates>;

    @Select(ApplicationState.getAccountServicerId)
    public accountServicerId$?: Observable<string>;

    @Select(ApplicationState.getSavedAllocationConstraints)
    public savedAllocationConstraints$!: Observable<SavedAllocationConstraint[]>;

    @Select(ApplicationState.getSimulationTopFundsLatestIndicator)
    public simulationTopFundsLatestIndicator$!: Observable<{ code: string, period: PeriodEnum } | null>;

    @Select(ApplicationState.getComparisonLists)
    public comparisonLists$!: Observable<ComparisonListModel[]>;

    @Select(ApplicationState.getMenus)
    public menu$!: Observable<Menu[]>;

    @Select(ApplicationState.getLastCheckSessionDate)
    public lastCheckSessionDate$!: Observable<string | null>;

    @Select(ApplicationState.getHasPortfolios)
    public hasPortfolios$!: Observable<boolean | undefined>;

    @Select(ApplicationState.getIsIntroductionEnhancementSkipped)
    public isIntroductionEnhancementSkipped$!: Observable<boolean>;

    @Select(ApplicationState.getIsIntroductionOptimalCreationSkipped)
    public isIntroductionOptimalCreationSkipped$!: Observable<boolean>;

    public userHasPortfolios$: Observable<boolean> = this.hasPortfolios$
        .pipe(
            switchMap((hasPortfolio) => {
                if (hasPortfolio) return of(true);
                return this.portfoliosService.hasPortfolio().pipe(
                    tap((newHasPortfolio) => {
                        if (newHasPortfolio) this.updateHasPortfolios(newHasPortfolio);
                    }),
                );
            }),
        );

    constructor(
        private store: Store,
        private actions$: Actions,
        private portfoliosService: PortfoliosService,
        private authorisationFacade: AuthorisationFacade,
    ) {}

    public addEntitiesToComparisonList(id: number, entities: ReferenceModel[]) {
        return this.store.dispatch(new AddEntitiesToComparisonListAction(id, entities));
    }

    public addOperation<T>(id: string, operation: Operation<T>): Observable<unknown> {
        return this.store.dispatch(new AddOperationAction(id, operation));
    }

    public createComparisonList(name: string, entities: ReferenceModel[]) {
        return this.store.dispatch(new CreateComparisonListAction(name, entities));
    }

    public deleteOperation(id: string): Observable<unknown> {
        return this.store.dispatch(new DeleteOperationAction(id));
    }

    public getCurrencies(isDefault: boolean = false): Observable<unknown> {
        return this.store.dispatch(new GetCurrenciesAction(isDefault));
    }

    public getMarketCycles(): Observable<unknown> {
        return this.store.dispatch(new GetMarketCyclesAction());
    }

    public getPeriods(): Observable<unknown> {
        return this.store.dispatch(new GetPeriodsAction());
    }

    public getRiskProfiles(): Observable<unknown> {
        return this.store.dispatch(new GetRiskProfilesAction());
    }

    public getAllocationStyles(): Observable<unknown> {
        return this.store.dispatch(new GetAllocationStylesAction());
    }

    public getContexts(): Observable<unknown> {
        return this.store.dispatch(new GetContextsAction());
    }

    public getFrequencies(): Observable<unknown> {
        return this.store.dispatch(new GetFrequenciesAction());
    }

    public getGeographies(): Observable<unknown> {
        return this.store.dispatch(new GetGeographiesAction());
    }


    public initApplication(authenticated = true): Observable<boolean> {
        if (!authenticated) {
            this.getCurrencies();
            this.getCurrencies(true);
            return of(true);
        }
        this.getCurrencies();
        this.getCurrencies(true);
        this.getMarketCycles();
        this.getPeriods();
        this.getRiskProfiles();
        this.getSrriVolatilities();

        return this.authorisationFacade.getAuthorisations()
            .pipe(map(() => true));
    }

    public resolverPortfolioDetails(): Observable<boolean> {
        return forkJoin([
            this.getAllocationStyles(),
            this.getContexts(),
            this.getFrequencies(),
            this.getGeographies(),
        ]).pipe(map(() => true));
    }

    public updateAccountServicerId(accountServicerId: string | undefined) {
        this.store.dispatch(new UpdateAccountServicerIdAction(accountServicerId));
    }

    public onConsentDispatched() {
        return this.actions$.pipe(ofActionDispatched(ConsentAction));
    }

    public getComparisonLists(): Observable<unknown> {
        return this.store.dispatch(new GetComparisonListsAction());
    }

    public getVideosShareCategories(): Observable<unknown> {
        return this.store.dispatch(new GetVideoCategoriesAction('FUND'));
    }

    public getVideosPortfolioCategories(): Observable<unknown> {
        return this.store.dispatch(new GetVideoCategoriesAction('PORTFOLIO'));
    }

    public getDocumentsShareCategories(): Observable<unknown> {
        return this.store.dispatch(new GetDocumentCategoriesAction('FUND'));
    }

    public getDocumentsPortfolioCategories(): Observable<unknown> {
        return this.store.dispatch(new GetDocumentCategoriesAction('PORTFOLIO'));
    }

    public replaceComparisonListEntities(id: number, entities: ReferenceModel[]): Observable<unknown> {
        return this.store.dispatch(new ReplaceComparisonListEntitiesAction(id, entities));
    }

    public renameComparisonList(id: number, name: string): Observable<unknown> {
        return this.store.dispatch(new RenameComparisonListAction(id, name));
    }

    public deleteComparisonList(id: number): Observable<unknown> {
        return this.store.dispatch(new DeleteComparisonListAction(id));
    }

    public updateDisplayLeftColumnPortfolio(displayLeftColumn: boolean): Observable<unknown> {
        return this.store.dispatch(new UpdateDisplayLeftColumnPortfolioAction(displayLeftColumn));
    }

    public updateDisplayLeftColumnShare(displayLeftColumn: boolean): Observable<unknown> {
        return this.store.dispatch(new UpdateDisplayLeftColumnShareAction(displayLeftColumn));
    }

    public updateSimulationTopFundsLatestIndicator(indicator: { code: string, period: PeriodEnum } | null): Observable<unknown> {
        return this.store.dispatch(new UpdateSimulationTopFundsLatestIndicatorAction(indicator));
    }

    public updateIsFreemiumOnboardingVisible(isFirstVisit: boolean): Observable<unknown> {
        return this.store.dispatch(new UpdateIsFreemiumOnboardingVisibleAction(isFirstVisit));
    }

    public updateLastCheckSessionDate(date: string | null): Observable<unknown> {
        return this.store.dispatch(new UpdateLastCheckSessionDateAction(date));
    }

    public updateHasPortfolios(hasPortfolio: boolean): Observable<unknown> {
        return this.store.dispatch(new HasPortfoliosAction(hasPortfolio));
    }

    public updateIsIntroductionEnhancementSkipped(isSkipped: boolean): Observable<unknown> {
        return this.store.dispatch(new UpdateIsIntroductionEnhancementSkippedAction(isSkipped));
    }

    public updateIsIntroductionOptimalCreationSkipped(isSkipped: boolean): Observable<unknown> {
        return this.store.dispatch(new UpdateIsIntroductionOptimalCreationSkippedAction(isSkipped));
    }

    public getSrriVolatilities(): Observable<unknown> {
        return this.store.dispatch(new GetSrriVolatilitiesAction());
    }

    public getSavedAllocationConstraints(): Observable<unknown> {
        return this.store.dispatch(new GetSavedAllocationConstraintsAction());
    }

    public getMenus(): Observable<unknown> {
        return this.store.dispatch(new GetMenusAction());
    }

    public getDefaultCurrenciesSnapshot(): Currency[] {
        return this.store.selectSnapshot(ApplicationState.getDefaultCurrencies);
    }

    public getComparisonListsSnapshot(): ComparisonListModel[] {
        return this.store.selectSnapshot(ApplicationState.getComparisonLists);
    }

    public getCurrenciesSnapshot(): Currency[] {
        return this.store.selectSnapshot(ApplicationState.getCurrencies);
    }

    public getGeographiesSnapshot(): Geography[] {
        return this.store.selectSnapshot(ApplicationState.getGeographies);
    }

    public getAllocationStylesSnapshot(): PortfolioAllocationStyle[] {
        return this.store.selectSnapshot(ApplicationState.getAllocationStyles);
    }

    public getFrequenciesSnapshot(): ListItemAlt[] {
        return this.store.selectSnapshot(ApplicationState.getFrequencies);
    }

    public getContextsSnapshot(): ListItem[] {
        return this.store.selectSnapshot(ApplicationState.getContexts);
    }

    public getVideosShareCategoriesSnapshot(): VideoCategory[] {
        return this.store.selectSnapshot(ApplicationState.getVideosShareCategories);
    }

    public getDocumentShareCategoriesSnapshot(): TabItem[] {
        return this.store.selectSnapshot(ApplicationState.getDocumentShareCategories);
    }

    public getVideosPortfolioCategoriesSnapshot(): VideoCategory[] {
        return this.store.selectSnapshot(ApplicationState.getVideosPortfolioCategories);
    }

    public getDocumentPortfolioCategoriesSnapshot(): TabItem[] {
        return this.store.selectSnapshot(ApplicationState.getDocumentPortfolioCategories);
    }

    public getOperationSnapshot(operationId: string): Operation<unknown> {
        return this.store.selectSnapshot(ApplicationState.getOperation(operationId));
    }

    public getRiskProfilesSnapshot(): RiskProfile[] {
        return this.store.selectSnapshot(ApplicationState.getRiskProfiles);
    }

    public getSrriVolatilitiesSnapshot(): SrriVolatility[] {
        return this.store.selectSnapshot(ApplicationState.getSrriVolatilities);
    }

    public getIsFreemiumOnboardingVisibleSnapshot(): boolean {
        return this.store.selectSnapshot(ApplicationState.getIsFreemiumOnboardingVisible);
    }

    public getSavedAllocationConstraintsSnapshot(): SavedAllocationConstraint[] {
        return this.store.selectSnapshot(ApplicationState.getSavedAllocationConstraints);
    }

    public getSimulationTopFundsLatestIndicatorSnapshot(): { code: string, period: PeriodEnum } | null {
        return this.store.selectSnapshot(ApplicationState.getSimulationTopFundsLatestIndicator);
    }

    public getIsIntroductionEnhancementSkippedSnapshot(): boolean {
        return this.store.selectSnapshot(ApplicationState.getIsIntroductionEnhancementSkipped);
    }

    public getIsIntroductionOptimalCreationSkippedSnapshot(): boolean {
        return this.store.selectSnapshot(ApplicationState.getIsIntroductionOptimalCreationSkipped);
    }

    public onReplaceComparisonListEntitiesSuccess(): Observable<number> {
        return this.actions$.pipe(
            ofActionSuccessful(ReplaceComparisonListEntitiesAction),
            map((action) => action.id),
        );
    }

    public onCreateComparisonListActionSuccess(): Observable<ComparisonListModel> {
        return this.actions$.pipe(
            ofActionSuccessful(CreateComparisonListSuccessAction),
            map((action) => action.item),
        );
    }

    public updateConsentState(consentFlowStateType: ConsentFlowStates) {
        this.store.dispatch(new ConsentAction(consentFlowStateType));
    }
}
