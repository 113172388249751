import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { uid } from 'uid';

import { AllocationConstraint } from '~/app/shared/types/allocation/allocation-constraint.type';
import {
    SavedAllocationConstraintResponse,
} from '~/app/shared/types/saved-allocation-constraint/saved-allocation-constraint-response.type';
import {
    SavedAllocationConstraint,
} from '~/app/shared/types/saved-allocation-constraint/saved-allocation-constraint.type';

@Injectable({
    providedIn: 'root',
})
export class SavedAllocationConstraintsService {
    public constructor(
        private http: HttpClient,
    ) {}

    public getSavedAllocationConstraints() {
        return this.http.get<Readonly<SavedAllocationConstraintResponse>>('/saved-allocation-constraints', {
            params: {
                startAt: '',
                size: 50,
            },
        }).pipe(
            map((result: Readonly<SavedAllocationConstraintResponse>): SavedAllocationConstraint[] => result.values.map((value) => ({
                id: value.id,
                name: value.name,
                constraints: value.constraints.map((constraint) => ({
                    ...constraint,
                    uid: uid(),
                })),
            }))),
        );
    }

    public postSavedAllocationConstraint(library: string, constraints: AllocationConstraint[]) {
        return this.http.post('/saved-allocation-constraints', {
            name: library,
            constraints,
        });
    }

    public putSavedAllocationConstraint(library: { id: number, label: string }, constraints: AllocationConstraint[]) {
        return this.http.put(`/saved-allocation-constraints/${library.id}`, {
            id: library.id,
            name: library.label,
            constraints,
        });
    }

    public deleteSavedAllocationConstraint(id: number) {
        return this.http.delete(`/saved-allocation-constraints/${id}`);
    }
}
