import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    EMPTY,
    expand,
    Observable,
    reduce,
} from 'rxjs';

import { ComparisonListModel } from '~/app/shared/types/api/comparison-list-model.type';
import { PageComparisonListModel } from '~/app/shared/types/api/page-comparison-list-model.type';
import { ReferenceModel } from '~/app/shared/types/api/reference-model.type';

@Injectable({
    providedIn: 'root',
})
export class ComparisonListsService {
    public constructor(
        private http: HttpClient,
    ) {}

    public getAllComparisonLists(): Observable<Readonly<PageComparisonListModel>> {
        let valuesLength: number = 0;

        return this.getComparisonLists().pipe(
            expand((result) => {
                valuesLength += result.values.length;
                return valuesLength >= result.totalNumber ? EMPTY : this.getComparisonLists(result.values[result.values.length - 1]?.id || 0);
            }),
            reduce((acc: Readonly<PageComparisonListModel>, val: Readonly<PageComparisonListModel>) => ({
                ...acc,
                values: [...acc.values, ...val.values],
            }), {
                totalNumber: 0,
                startAt: 0,
                size: 0,
                number: 0,
                values: [],
            }),
        );
    }

    public getComparisonList(id: number): Observable<ComparisonListModel> {
        return this.http.get<ComparisonListModel>(`/comparison-lists/${id}`);
    }

    public renameComparisonList(id: number, name: string): Observable<ComparisonListModel> {
        return this.http.put<ComparisonListModel>(`/comparison-lists/${id}`, {
            name,
        });
    }

    public deleteComparisonList(id: number): Observable<unknown> {
        return this.http.delete(`/comparison-lists/${id}`);
    }

    public getComparisonLists(startAt: number = 0, size: number = 50): Observable<PageComparisonListModel> {
        return this.http.get<PageComparisonListModel>(
            '/comparison-lists',
            {
                params: {
                    size,
                    startAt,
                },
            },
        );
    }

    public createComparisonList(name: string) {
        return this.http.post<ComparisonListModel>('/comparison-lists', {
            name,
        });
    }

    public getComparisonListEntities(id: number): Observable<ReferenceModel[]> {
        return this.http.get<ReferenceModel[]>(`/comparison-lists/${id}/entities`);
    }

    public addEntitiesToComparisonList(id: number, data: ReferenceModel[]): Observable<unknown> {
        return this.http.post(`/comparison-lists/${id}/entities`, data);
    }

    public deleteEntitiesToComparisonList(id: number, data: ReferenceModel[]) {
        return this.http.delete<ComparisonListModel>(`/comparison-lists/${id}/entities`, {
            body: data,
        });
    }
}
