import { PERMISSIONS } from '~/app/core/constants/permissions.constants';
import { ActiveRiskCode } from '~/app/shared/enums/active-risk-code.enum';
import { Benchmark } from '~/app/shared/enums/benchmark.enum';
import { MarketCycleCode } from '~/app/shared/enums/market-cycle-code.enum';
import { NotationCode } from '~/app/shared/enums/notation-code.enum';
import { Period } from '~/app/shared/enums/period.enum';
import { RiskIndicatorCode } from '~/app/shared/enums/risk-indicator-code.enum';
import { ShareViewModeValue } from '~/app/shared/enums/share-view-mode-value.enum';
import {
    ActiveRiskConfig,
    NotationValueRequest,
    RiskIndicatorValueRequest,
} from '~/app/shared/interfaces/RiskIndicator';
import { ScatterChartPairIndicator } from '~/app/shared/types/chart/scatter/scatter-chart-pair-indicator.type';
import { OptionAuthorisation } from '~/app/shared/types/option-authorisation.type';

export const SHARE_DETAIL_GENERAL_NOTATION_SCAT: NotationValueRequest = {
    code: NotationCode.NSP,
    marketCycle: MarketCycleCode.OV,
    benchmark: Benchmark.BSCAT,
    period: Period.P03Y,
};

export const SHARE_DETAIL_PERF_SIC: RiskIndicatorValueRequest = {
    code: RiskIndicatorCode.PERF,
    marketCycle: MarketCycleCode.OV,
    period: Period.PSIC,
};

export const SHARE_DETAIL_REPORTING_PERIODS: string[] = [
    Period.PYTD,
    Period.P06M,
    Period.P01Y,
    Period.P03Y,
    Period.P05Y,
    Period.P10Y,
];

export const SHARE_DETAIL_DEFAULT_PERIOD: string = Period.PYTD;

export const SHARE_DETAIL_RISK_INDICATORS_OVERVIEW: RiskIndicatorCode[] = [
    RiskIndicatorCode.PERF,
    RiskIndicatorCode.MADD,
    RiskIndicatorCode.VOLT,
];

export const SHARE_DETAIL_RISK_INDICATORS_ANALYSIS_MAIN: RiskIndicatorCode[] = [
    RiskIndicatorCode.ANRT,
    RiskIndicatorCode.PERF,
    RiskIndicatorCode.MADD,
    RiskIndicatorCode.RCTM,
];

export const SHARE_DETAIL_RISK_INDICATORS_ANALYSIS_PRO: RiskIndicatorCode[] = [
    RiskIndicatorCode.VOLT,
    RiskIndicatorCode.SHRT,
];

export const SHARE_DETAIL_RISK_INDICATORS_ANALYSIS_ADVANCED: RiskIndicatorCode[] = [
    RiskIndicatorCode.VR5D,
    RiskIndicatorCode.VR5W,
    RiskIndicatorCode.VR9D,
    RiskIndicatorCode.VR9W,
];

export const SHARE_DETAIL_ALL_ACTIVE_RISK_ADVANCED: ActiveRiskConfig[] = [
    {
        code: ActiveRiskCode.APRF,
        benchmark: Benchmark.BSCAT,
    },
    {
        code: ActiveRiskCode.APRF,
        benchmark: Benchmark.BCAT,
    },
    {
        code: ActiveRiskCode.BETA,
        benchmark: Benchmark.BSCAT,
    },
    {
        code: ActiveRiskCode.BETA,
        benchmark: Benchmark.BCAT,
    },
    {
        code: ActiveRiskCode.TKER,
        benchmark: Benchmark.BSCAT,
    },
    {
        code: ActiveRiskCode.TKER,
        benchmark: Benchmark.BCAT,
    },
    {
        code: ActiveRiskCode.ERTN,
        benchmark: Benchmark.BSCAT,
    },
    {
        code: ActiveRiskCode.ERTN,
        benchmark: Benchmark.BCAT,
    },
    {
        code: ActiveRiskCode.IFRT,
        benchmark: Benchmark.BCAT,
    },
    {
        code: ActiveRiskCode.IFRT,
        benchmark: Benchmark.BSCAT,
    },
    {
        code: ActiveRiskCode.UPRT,
        benchmark: Benchmark.BCAT,
    },
    {
        code: ActiveRiskCode.UPRT,
        benchmark: Benchmark.BSCAT,
    },
    {
        code: ActiveRiskCode.DNRT,
        benchmark: Benchmark.BCAT,
    },
    {
        code: ActiveRiskCode.DNRT,
        benchmark: Benchmark.BSCAT,
    },
];

export const SHARE_DETAIL_ACTIVE_RISK_ANALYSIS_ADVANCED: ActiveRiskConfig[] = [
    {
        code: ActiveRiskCode.BETA,
        benchmark: Benchmark.BSCAT,
    },
    {
        code: ActiveRiskCode.BETA,
        benchmark: Benchmark.BCAT,
    },
    {
        code: ActiveRiskCode.TKER,
        benchmark: Benchmark.BSCAT,
    },
    {
        code: ActiveRiskCode.TKER,
        benchmark: Benchmark.BCAT,
    },
    {
        code: ActiveRiskCode.IFRT,
        benchmark: Benchmark.BCAT,
    },
    {
        code: ActiveRiskCode.IFRT,
        benchmark: Benchmark.BSCAT,
    },
];

export const SHARE_DETAILS_VIEW_MODES_VALUES: OptionAuthorisation<ShareViewModeValue>[] = [
    {
        id: ShareViewModeValue.INVESTMENT_OBJECTIVE,
        label: 'share_details.management.view_mode_values.investment_objective',
    },
    {
        id: ShareViewModeValue.VIDEO,
        label: 'share_details.management.view_mode_values.video',
        permissions: [PERMISSIONS.FUND_SHEET_VIDEO_ACCESS],
    },
    {
        id: ShareViewModeValue.ROLLING_PERIOD_OVERVIEW,
        label: 'share_details.management.view_mode_values.rolling_period_overview',
    },
    {
        id: ShareViewModeValue.RISK_RETURN_NEAREST,
        label: 'share_details.management.view_mode_values.risk_return_nearest',
    },
    {
        id: ShareViewModeValue.ROLLING_PERIOD_ANALYSIS,
        label: 'share_details.management.view_mode_values.rolling_period_analysis',
    },
    {
        id: ShareViewModeValue.CALENDAR_PERFORMANCE,
        label: 'share_details.management.view_mode_values.calendar_performance',
    },
    {
        id: ShareViewModeValue.MARKET_CYCLE_ANALYSIS,
        label: 'share_details.management.view_mode_values.market_cycle_analysis',
        permissions: [PERMISSIONS.FUND_SHEET_MARKET_CYCLE_ANALYSIS_ACCESS],
    },
    {
        id: ShareViewModeValue.MARKET_CYCLE_PERFORMANCE,
        label: 'share_details.management.view_mode_values.market_cycle_performance',
        permissions: [PERMISSIONS.FUND_SHEET_MARKET_CYCLE_ANALYSIS_ACCESS],
    },
    {
        id: ShareViewModeValue.LEGAL_DOCUMENTS,
        label: 'share_details.management.view_mode_values.legal_documents',
    },
    {
        id: ShareViewModeValue.ASSURANCE_VIE_ELIGIBILITY,
        label: 'share_details.management.view_mode_values.assurance_vie_eligibility',
        permissions: [PERMISSIONS.FUND_SHEET_LIFE_INSURANCE_ACCESS],
    },
];

export const SHARE_DETAILS_VIEW_MODES_DEFAULT_VALUES = {
    MAIN: [
        ShareViewModeValue.INVESTMENT_OBJECTIVE,
        ShareViewModeValue.VIDEO,
        ShareViewModeValue.ROLLING_PERIOD_OVERVIEW,
        ShareViewModeValue.ROLLING_PERIOD_ANALYSIS,
        ShareViewModeValue.LEGAL_DOCUMENTS,
    ],
    PRO: [
        ShareViewModeValue.INVESTMENT_OBJECTIVE,
        ShareViewModeValue.VIDEO,
        ShareViewModeValue.ROLLING_PERIOD_OVERVIEW,
        ShareViewModeValue.ROLLING_PERIOD_ANALYSIS,
        ShareViewModeValue.ROLLING_PERIOD_RECOVERY_TIME,
        ShareViewModeValue.CALENDAR_PERFORMANCE,
        ShareViewModeValue.LEGAL_DOCUMENTS,
        ShareViewModeValue.ASSURANCE_VIE_ELIGIBILITY,
    ],
    ADVANCED: [
        ShareViewModeValue.INVESTMENT_OBJECTIVE,
        ShareViewModeValue.VIDEO,
        ShareViewModeValue.ROLLING_PERIOD_OVERVIEW,
        ShareViewModeValue.ROLLING_PERIOD_ANALYSIS,
        ShareViewModeValue.ROLLING_PERIOD_RECOVERY_TIME,
        ShareViewModeValue.CALENDAR_PERFORMANCE,
        ShareViewModeValue.MARKET_CYCLE_ANALYSIS,
        ShareViewModeValue.MARKET_CYCLE_PERFORMANCE,
        ShareViewModeValue.RISK_RETURN_NEAREST,
        ShareViewModeValue.LEGAL_DOCUMENTS,
        ShareViewModeValue.ASSURANCE_VIE_ELIGIBILITY,
    ],
};

export const SHARE_DETAILS_MARKET_CYCLE_ANALYSIS = {
    marketCycles: [
        {
            icon: 'ui/market/state/stable',
            title: 'common.marketCycles.ST.label',
            subtitle: 'common.marketCycles.ST.description',
            marketCycle: MarketCycleCode.ST,
            notationCode: NotationCode.NST,
        },
        {
            icon: 'ui/market/state/unstable',
            title: 'common.marketCycles.UN.label',
            subtitle: 'common.marketCycles.UN.description',
            marketCycle: MarketCycleCode.UN,
            notationCode: NotationCode.NUN,
        },
        {
            icon: 'ui/market/state/crisis',
            title: 'common.marketCycles.CR.label',
            subtitle: 'common.marketCycles.CR.description',
            marketCycle: MarketCycleCode.CR,
            notationCode: NotationCode.NCR,
        },
        {
            icon: 'ui/market/state/recovery',
            title: 'common.marketCycles.RE.label',
            subtitle: 'common.marketCycles.RE.description',
            marketCycle: MarketCycleCode.RE,
            notationCode: NotationCode.NRE,
        },
    ],
    lines: [
        {
            title: 'common.active_risks.ERTN.label',
            code: ActiveRiskCode.ERTN,
            columns: [],
        },
        {
            title: 'common.active_risks.BETA.label',
            code: ActiveRiskCode.BETA,
            columns: [],
        },
        {
            title: 'common.active_risks.TKER.label',
            code: ActiveRiskCode.TKER,
            columns: [],
        },
    ],
};

export const SHARE_DETAILS_MARKET_CYCLE_ANALYSIS_INDICATOR_STANDARD: ActiveRiskCode[] = [
    ActiveRiskCode.ERTN,
];

export const SHARE_DETAILS_MARKET_CYCLE_ANALYSIS_INDICATOR_BY_BETA: ActiveRiskCode[] = [
    ActiveRiskCode.ARTN,
    ActiveRiskCode.EBRN,
];

export const SHARE_DETAILS_MARKET_CYCLE_ANALYSIS_INDICATOR_BY_SKILLS: ActiveRiskCode[] = [
    ActiveRiskCode.RATC,
    ActiveRiskCode.RAVC,
    ActiveRiskCode.RAAC,
];

export const SHARE_DETAILS_PAIR_RISK_INDICATOR: ScatterChartPairIndicator[] = [
    {
        indicators: {
            ordinate: RiskIndicatorCode.ANRT,
            abscissa: RiskIndicatorCode.VOLT,
        },
        order: RiskIndicatorCode.SHRT,

    },
];

export const SHARE_DETAILS_PAIR_ACTIVE_RISK: ScatterChartPairIndicator[] = [
    {
        indicators: {
            ordinate: ActiveRiskCode.ERTN,
            abscissa: ActiveRiskCode.TKER,
        },
        order: ActiveRiskCode.IFRT,
        benchmark: Benchmark.BCAT,
    },
    {
        indicators: {
            ordinate: ActiveRiskCode.ERTN,
            abscissa: ActiveRiskCode.TKER,
        },
        order: ActiveRiskCode.IFRT,
        benchmark: Benchmark.BSCAT,
    },
    {
        indicators: {
            ordinate: ActiveRiskCode.APRF,
            abscissa: ActiveRiskCode.BETA,
        },
        order: ActiveRiskCode.APRF,
        benchmark: Benchmark.BCAT,
    },
    {
        indicators: {
            ordinate: ActiveRiskCode.APRF,
            abscissa: ActiveRiskCode.BETA,
        },
        order: ActiveRiskCode.APRF,
        benchmark: Benchmark.BSCAT,
    },
    {
        indicators: {
            ordinate: ActiveRiskCode.UPRT,
            abscissa: ActiveRiskCode.DNRT,
        },
        order: null,
        benchmark: Benchmark.BCAT,
    },
    {
        indicators: {
            ordinate: ActiveRiskCode.UPRT,
            abscissa: ActiveRiskCode.DNRT,
        },
        order: null,
        benchmark: Benchmark.BSCAT,
    },
];

export const SHARE_DETAILS_SCATTER_PERIODS_ENABLED = [
    '01Y',
    '03Y',
    '05Y',
    '10Y',
];
