import { ActiveRiskCode } from '~/app/shared/enums/active-risk-code.enum';
import { RiskIndicatorCode } from '~/app/shared/enums/risk-indicator-code.enum';

export const INDICATOR_PERF = [
    RiskIndicatorCode.IART,
    RiskIndicatorCode.PERF,
    RiskIndicatorCode.RART,
    ActiveRiskCode.ARTN,
    ActiveRiskCode.BRTN,
    ActiveRiskCode.ERTN,
    ActiveRiskCode.EBRN,
    ActiveRiskCode.APRF,
    ActiveRiskCode.BPRF,
    ActiveRiskCode.EPEF,
    ActiveRiskCode.ERAR,
    ActiveRiskCode.ERRB,
    ActiveRiskCode.RAAC,
    ActiveRiskCode.RATC,
    ActiveRiskCode.RAVC,
    ActiveRiskCode.RASC,
    ActiveRiskCode.ARTN,
];

export const INDICATOR_RISK = [
    RiskIndicatorCode.ETYP,
    ActiveRiskCode.UPRT,
    ActiveRiskCode.DNRT,
    ActiveRiskCode.BETA,
    ActiveRiskCode.TKER,
    ActiveRiskCode.TEAC,
    ActiveRiskCode.TEBC,
    ActiveRiskCode.VOLA,
    ActiveRiskCode.VOLB,
    RiskIndicatorCode.MCFQ,
    RiskIndicatorCode.MADD,
    RiskIndicatorCode.RCTM,
    RiskIndicatorCode.RCTM,
    RiskIndicatorCode.VR5D,
    RiskIndicatorCode.VR5D,
    RiskIndicatorCode.VR5W,
    RiskIndicatorCode.VR9D,
    RiskIndicatorCode.VR9W,
    RiskIndicatorCode.VOLT,
    RiskIndicatorCode.ABYR,
    RiskIndicatorCode.MCCY,
];

export const INDICATOR_PERF_RISK_RATIO = [
    ActiveRiskCode.IFRT,
    ActiveRiskCode.IRAC,
    ActiveRiskCode.IRBC,
    ActiveRiskCode.SRAC,
    ActiveRiskCode.SRBC,
    RiskIndicatorCode.CALR,
    RiskIndicatorCode.SHRT,
];
