export enum PortfolioUploadType {
    AMOUNT_OR_WEIGHT = 'AW',
    QUANTITY = 'QTY',
    NO_ALLOCATION = 'NC',
}

export enum PortfolioUploadStatus {
    MISSING = 'missing',
    FOUND = 'found',
    WRONG = 'wrong',
    CONFLICTED = 'conflicted'
}
