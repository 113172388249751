import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Memoize } from '~/app/core/decorators/memoize.decorators';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { AuthorisationFacade } from '~/app/core/state/authorisation/authorisation.facade';
import { Menu } from '~/app/shared/types/api/menu.type';

@Injectable({
    providedIn: 'root',
})
export class TenantsService {
    public constructor(
        private http: HttpClient,
        private authFacade: AuthenticationFacade,
        private authorisationFacade: AuthorisationFacade,
    ) {}

    public getMenus(): Observable<Readonly<Menu[]>> {
        const language = this.authFacade.getUserLanguageSnapshot();
        const plan = this.authorisationFacade.getPlanSnapshot();

        return this.getMenusRaw(
            language,
            plan?.id ?? null,
        );
    }

    @Memoize({
        isObservable: true,
    })
    private getMenusRaw(
        _languageCode: string, // keep for memoization
        _plan: number | null, // keep for memoization
    ) {
        return this.http.get<Readonly<Menu[]>>('/tenants/configurations/menu');
    }
}
