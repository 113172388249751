import {
    ChangeDetectionStrategy,
    Component,
    Input,
} from '@angular/core';

import { IconComponent } from '../icon/icon.component';

@Component({
    selector: 'eb-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [IconComponent],
})
export class AlertComponent {
    /**
     * Set the alert's type
     */
    @Input() public type: 'info' | 'success' | 'warning' | 'error' = 'success';

    @Input() public size: 'normal' | 'small' = 'normal';

    @Input() public radius = true;

    /**
     * Set the alert's text
     */
    @Input() public text?: string;

    get icon(): string {
        return {
            info: 'ui/action/symbols/info',
            success: 'ui/action/symbols/check',
            warning: 'ui/action/symbols/alert-triangle',
            error: 'ui/action/symbols/close',
        }[this.type];
    }
}
