import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AlertActionParam } from '~/app/shared/types/alert-action/alert-action-param.type';
import { AlertAction } from '~/app/shared/types/alert-action/alert-action.type';

@Injectable({
    providedIn: 'root',
})
export class AlertsService {
    constructor(private http: HttpClient) {}

    getAlertActions() {
        return this.http.get<Readonly<AlertAction[]>>('/alerts/actions');
    }

    postAlertAction(params: AlertActionParam) {
        return this.http.post<Readonly<string>>(`/alerts/${params.alertId}/action`, {
            code: params.code,
            ...(params.parameters ? { parameters: params.parameters } : {}),
        });
    }
}
