import {
    HttpClient,
    HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Observable,
    of,
} from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Memoize } from '~/app/core/decorators/memoize.decorators';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { AuthorisationFacade } from '~/app/core/state/authorisation/authorisation.facade';
import { EntityType } from '~/app/shared/enums/entity-type.enum';
import { RiskIndicatorsModule } from '~/app/shared/enums/risk-indicators-module.enum';
import {
    MarketCycle,
    RiskIndicatorSort,
    RiskIndicatorValueRequest,
    RiskIndicatorValueResponse,
} from '~/app/shared/interfaces/RiskIndicator';
import { IndicatorsUtilsService } from '~/app/shared/services/indicators-utils/indicators-utils.service';
import { Period } from '~/app/shared/types/period.type';

@Injectable({
    providedIn: 'root',
})
export class RiskIndicatorsService {
    constructor(
        private http: HttpClient,
        private indicatorsUtilsService: IndicatorsUtilsService,
        private authFacade: AuthenticationFacade,
        private authorizationFacade: AuthorisationFacade,
    ) {}

    getMarketCycles() {
        const languageCode = this.authFacade.getUserLanguageSnapshot();
        return this.getMarketCyclesRaw(languageCode);
    }

    getPeriods() {
        const languageCode = this.authFacade.getUserLanguageSnapshot();
        return this.getPeriodsRaw(languageCode);
    }

    getRiskIndicators(module: RiskIndicatorsModule, q?: string) {
        const languageCode = this.authFacade.getUserLanguageSnapshot();
        const plan = this.authorizationFacade.getPlanSnapshot();

        return this.getRiskIndicatorsRaw(languageCode, plan?.id ?? null, module, q);
    }

    getRiskIndicatorsValuesForSharesOrCategories(
        sharesId: Array<number>,
        categoriesId: Array<number>,
        riskIndicators: Array<RiskIndicatorValueRequest>,
        endDate: string | null = null,
        includeValues: boolean = true,
    ): Observable<ReadonlyArray<RiskIndicatorValueResponse>> {
        if (riskIndicators.length === 0 || (sharesId.length === 0 && categoriesId.length === 0)) { return of([]); }
        const references = this.indicatorsUtilsService.createReferencesForRequest(sharesId, categoriesId);
        return this.http.post<ReadonlyArray<RiskIndicatorValueResponse>>(
            '/risk-indicators/values',
            {
                references,
                riskIndicators,
                ...(endDate ? { endDate } : {}),
                includeValues,
            },
        ).pipe(
            catchError(() => of([] as ReadonlyArray<RiskIndicatorValueResponse>)),
        );
    }

    getRiskIndicatorsValuesForPortfolios(
        portfoliosId: Array<number>,
        riskIndicators: Array<RiskIndicatorValueRequest>,
        endDate: string | null = null,
        includeValues: boolean = true,
    ): Observable<ReadonlyArray<RiskIndicatorValueResponse>> {
        if (riskIndicators.length === 0 || portfoliosId.length === 0) {
            return of([] as ReadonlyArray<RiskIndicatorValueResponse>);
        }

        return this.http.post<ReadonlyArray<RiskIndicatorValueResponse>>(
            '/portfolios/risk-indicators/values',
            {
                references: portfoliosId.map((shareId) => ({
                    id: shareId,
                    type: EntityType.PORTFOLIO,
                })),
                riskIndicators,
                ...(endDate ? { endDate } : {}),
                includeValues,
            },
        ).pipe(
            catchError(() => of([] as ReadonlyArray<RiskIndicatorValueResponse>)),
        );
    }

    @Memoize({
        isObservable: true,
    })
    private getPeriodsRaw(
        _languageCode: string, // keep for memoization
    ) {
        return this.http.get<Readonly<Period[]>>('/risk-indicators/periods');
    }

    @Memoize({
        isObservable: true,
        maxSize: 3,
    })
    private getRiskIndicatorsRaw(
        _languageCode: string, // keep for memoization
        _planId: number | null, // keep for memoization
        module: RiskIndicatorsModule,
        q?: string,
    ) {
        let params = new HttpParams();
        if (q) {
            params = params.set('q', q);
        }
        if (module) {
            params = params.set('module', module);
        }
        return this.http.get<Readonly<RiskIndicatorSort[]>>('/risk-indicators', {
            params,
        });
    }

    @Memoize({
        isObservable: true,
    })
    private getMarketCyclesRaw(
        _languageCode: string, // keep for memoization
    ) {
        return this.http.get<Readonly<MarketCycle[]>>('/risk-indicators/market-cycles');
    }
}
