import {
    CARD_DEFAULT_UID,
    CARD_RISK_INDICATORS_UID,
} from '~/app/core/constants/portfolios.constants';
import { CardType } from '~/app/shared/enums/card-type.enum';
import { FacetType } from '~/app/shared/enums/facet-type.enum';
import { Period } from '~/app/shared/enums/period.enum';
import { PortfolioViewModeValue } from '~/app/shared/enums/portfolio-view-mode-value.enum';
import { RiskIndicatorCode } from '~/app/shared/enums/risk-indicator-code.enum';
import { AdvancedFilterBooleanValue } from '~/app/shared/types/advanced-filter/advanced-filter-boolean-value.type';
import { AdvancedFilterGeneric } from '~/app/shared/types/advanced-filter/advanced-filter-generic.type';
import { AdvancedFilterSelectValue } from '~/app/shared/types/advanced-filter/advanced-filter-select-value.type';
import { FieldOption } from '~/app/shared/types/field-option.type';
import { FieldValueEnum } from '~/app/shared/types/field-value-type.type';
import { Option } from '~/app/shared/types/option.type';

export const PORTFOLIO_DETAIL_ADD_PORTFOLIO_LIST_KEY = 'DETAILS_ADD_PORTFOLIO';

export const DISPLAY_INDICATORS_VALUE_FOR_COMPOSITION = [
    {
        uid: CARD_DEFAULT_UID,
        type: CardType.FACET,
        code: 'srri',
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.PERF,
        period: Period.P06M,
    },
    {
        uid: CARD_RISK_INDICATORS_UID,
        type: CardType.RISK_INDICATOR,
        code: RiskIndicatorCode.MADD,
        period: Period.P01Y,
    },
];

export const MAP_OPTIONS_FOR_COMPOSITION: {[key:string]: FieldOption} = {
    srri: {
        code: 'srri',
        name: 'shares_list.sort.fields.default.srri',
        type: FieldValueEnum.RISK,
        translatable: true,
        sortable: true,
        isVisible: true,
    },
};

export const PORTFOLIO_DETAIL_SHARE_LIST_EXTRA_FILTERS = [
    {
        uid: 'extra_active',
        name: 'Part active',
        type: FacetType.BOOLEAN,
        value: {
            property: 'isAlive',
            value: true,
        },
    } as AdvancedFilterGeneric<AdvancedFilterBooleanValue>,
];

export const PORTFOLIO_DETAILS_VIEW_MODES_VALUES: Option<PortfolioViewModeValue>[] = [
    {
        id: PortfolioViewModeValue.OBJECTIVE,
        label: 'portfolios_details.management.view_mode_values.objective',
    },
    {
        id: PortfolioViewModeValue.COMMENTS,
        label: 'portfolios_details.management.view_mode_values.comments',
    },
    {
        id: PortfolioViewModeValue.VIDEO,
        label: 'portfolios_details.management.view_mode_values.video',
    },
    {
        id: PortfolioViewModeValue.CAPITAL_AND_PROFILE,
        label: 'portfolios_details.management.view_mode_values.capital_and_performance',
    },
    {
        id: PortfolioViewModeValue.RESPONSIBLE_INVESTMENT,
        label: 'portfolios_details.management.view_mode_values.responsible_investment',
    },
    {
        id: PortfolioViewModeValue.PERFORMANCE_EVOLUTION,
        label: 'portfolios_details.management.view_mode_values.performance_evolution',
    },
    {
        id: PortfolioViewModeValue.RISK_INDICATOR_ANALYSIS,
        label: 'portfolios_details.management.view_mode_values.risk_indicator_analysis',
    },
    {
        id: PortfolioViewModeValue.AVERAGE_COMPOSITION,
        label: 'portfolios_details.management.view_mode_values.average_composition',
    },
    {
        id: PortfolioViewModeValue.PERFORMANCE_CONTRIBUTION,
        label: 'portfolios_details.management.view_mode_values.performance_contribution',
    },
    {
        id: PortfolioViewModeValue.FUND_LIST_AND_DOCUMENTS,
        label: 'portfolios_details.management.view_mode_values.fund_list_and_documents',
    },
    {
        id: PortfolioViewModeValue.ASSURANCE_VIE_ELIGIBILITY,
        label: 'portfolios_details.management.view_mode_values.assurance_vie_eligibility',
    },
];

export const PORTFOLIO_DETAILS_VIEW_MODES_DEFAULT_VALUES = {
    MAIN: [
        PortfolioViewModeValue.OBJECTIVE,
        PortfolioViewModeValue.COMMENTS,
        PortfolioViewModeValue.VIDEO,
        PortfolioViewModeValue.CAPITAL_AND_PROFILE,
        PortfolioViewModeValue.RESPONSIBLE_INVESTMENT,
        PortfolioViewModeValue.PERFORMANCE_EVOLUTION,
    ],
    PRO: [
        PortfolioViewModeValue.OBJECTIVE,
        PortfolioViewModeValue.COMMENTS,
        PortfolioViewModeValue.VIDEO,
        PortfolioViewModeValue.CAPITAL_AND_PROFILE,
        PortfolioViewModeValue.RESPONSIBLE_INVESTMENT,
        PortfolioViewModeValue.PERFORMANCE_EVOLUTION,
        PortfolioViewModeValue.RISK_INDICATOR_ANALYSIS,
        PortfolioViewModeValue.AVERAGE_COMPOSITION,
        PortfolioViewModeValue.PERFORMANCE_CONTRIBUTION,
    ],
    ADVANCED: [
        PortfolioViewModeValue.OBJECTIVE,
        PortfolioViewModeValue.COMMENTS,
        PortfolioViewModeValue.VIDEO,
        PortfolioViewModeValue.CAPITAL_AND_PROFILE,
        PortfolioViewModeValue.RESPONSIBLE_INVESTMENT,
        PortfolioViewModeValue.PERFORMANCE_EVOLUTION,
        PortfolioViewModeValue.RISK_INDICATOR_ANALYSIS,
        PortfolioViewModeValue.AVERAGE_COMPOSITION,
        PortfolioViewModeValue.PERFORMANCE_CONTRIBUTION,
        PortfolioViewModeValue.FUND_LIST_AND_DOCUMENTS,
        PortfolioViewModeValue.ASSURANCE_VIE_ELIGIBILITY,
    ],
};

export const PORTFOLIO_DETAIL_REPORTING_PERIODS: string[] = [
    Period.PYTD,
    Period.P01M,
    Period.P03M,
    Period.P06M,
    Period.P01Y,
    Period.P03Y,
    Period.P05Y,
];

export const PORTFOLIO_DETAIL_INDICATORS_PERIODS: string[] = [
    Period.PYTD,
    Period.P03M,
    Period.P06M,
    Period.P01Y,
    Period.P03Y,
    Period.P05Y,
];

export const PORTFOLIO_DETAIL_DEFAULT_PERIOD: string = Period.PYTD;

export const PORTFOLIO_DETAIL_RISK_INDICATORS_MAIN: RiskIndicatorCode[] = [
    RiskIndicatorCode.PERF,
    RiskIndicatorCode.MADD,
];

export const PORTFOLIO_DETAIL_RISK_INDICATORS_PRO: RiskIndicatorCode[] = [
    RiskIndicatorCode.VOLT,
    RiskIndicatorCode.SHRT,
    RiskIndicatorCode.RCTM,
];

export const PORTFOLIO_DETAIL_RISK_INDICATORS_ADVANCED: RiskIndicatorCode[] = [
    RiskIndicatorCode.CALR,
    RiskIndicatorCode.VR9D,
    RiskIndicatorCode.VR9W,
];

export const FILE_SIZE_LIMIT_MO: number = 10;

export const VIDEO_SIZE_LIMIT_MO: number = 100;

export const PORTFOLIO_DETAIL_ADVICES_LIST_EXTRA_FILTERS = [
    {
        uid: 'type',
        name: 'Type de portefeuille',
        type: FacetType.MULTI_CHOICE,
        value: {
            property: 'type',
            union: true,
            exclude: false,
            values: [
                'type|1', // Model
            ],
        },
    } as AdvancedFilterGeneric<AdvancedFilterSelectValue>,
];
